import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  threeDots: {
    fontSize: '20px',
    cursor: 'pointer',

  },
  threeDotsColor: {
    fontSize: '20px',
    cursor: 'pointer',
    backgroundColor: '#F4F5F9'
  },
  dropDown: {
    position: "absolute",
    listStyleType: 'none',
    padding:"0px",
    top: "25px",
    right: "5px",
    textAlign: 'start',
    backgroundColor: "#F4F5F9",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
   
    // border: "1px solid #ddd",
    zIndex: 1000,
    width: "120px",
    borderBlockStyle: 'none'
  },
  dropDownItem: {
    padding: "10px 16px",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#444e5e",
      color:'white'
    },
    
  }

}))