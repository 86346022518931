import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  notFoundEmojiIcon: {
    height: '50%',
    width: '50%',
    fill: ' #7d7c7c',
    '@media (max-width: 768px)': {
      height: '30%',
      width: '70%'
    }
  },
  keyIcon: {
    fill: '#034DAB',
    height: '25px !important',
    width: '25px !important',
    alignSelf: 'center',
    marginRight: '5px',
    '@media (max-width: 768px)': {
      height: '20px !important',
      width: '20px !important'
    }
  },
}));
