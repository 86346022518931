import React from 'react';
import { useStyles } from './GroupUsers.style';
// import { format } from 'timeago.js';
import { GroupUsersProps } from './GroupUsers.type';

// import { split } from 'lodash';

const letterColors: { [key: string]: string } = {
  A: '#ffddd6', // Red-Orange
  B: '#d6f0ff', // Light Blue
  C: '#daf7d5', // Green
  D: '#fff3cc', // Yellow
  E: '#C70039', // Dark Red
  F: '#f4ccd7', // Burgundy
  G: '#ded1da', // Purple
  H: '#d2e9ff', // Dodger Blue
  I: '#e1e9ec', // Light Green
  J: '#ffe8cc', // Dark Orange
  K: '#ffd0e9', // Deep Pink
  L: '#eff9fa', // Powder Blue
  M: '#d5e8dd', // Sea Green
  N: '#f05bee', // Gold
  O: '#e8cccc', // Dark Red
  P: '#ffdacc', // Orange Red
  Q: '#daf6f5', // Turquoise
  R: '#ffe0da', // Tomato
  S: '#d9f9f6', // Turquoise
  T: '#e5e1fc', // Medium Slate Blue
  U: '#e1def5', // Slate Blue
  V: '#e3edde', // Dark Turquoise
  W: '#e0ecd4', // Medium Orchid
  X: '#e9e2f8', // Medium Purple
  Y: '#d8f0e3', // Medium Sea Green
  Z: '#dae6f0' // Steel Blue
};

const textColors: { [key: string]: string } = {
  A: '#FF5733', // Red-Orange
  B: '#33B5FF', // Light Blue
  C: '#44D62C', // Green
  D: '#FFC300', // Yellow
  E: '#C70039', // Dark Red
  F: '#900C3F', // Burgundy
  G: '#581845', // Purple
  H: '#1E90FF', // Dodger Blue
  I: '#6892A0', // Light Green
  J: '#FF8C00', // Dark Orange
  K: '#FF1493', // Deep Pink
  L: '#B0E0E6', // Powder Blue
  M: '#2E8B57', // Sea Green
  N: '#f05bee', // Gold
  O: '#8B0000', // Dark Red
  P: '#FF4500', // Orange Red
  Q: '#48D1CC', // Turquoise
  R: '#FF6347', // Tomato
  S: '#40E0D0', // Turquoise
  T: '#7B68EE', // Medium Slate Blue
  U: '#6A5ACD', // Slate Blue
  V: '#74a358', // Dark Turquoise
  W: '#65a12a', // Medium Orchid
  X: '#9370DB', // Medium Purple
  Y: '#3CB371', // Medium Sea Green
  Z: '#4682B4' // Steel Blue
};

const GroupUsers: React.FC<GroupUsersProps> = ({ userDetails , roomUserClicked}) => {
  const { classes } = useStyles(); // ✅ Always called

  if (!userDetails?.user_details) return null;
  // console.log('userDetailsinGroup', userDetails);
  const roomName = userDetails?.user_details?.user_name || '';
  const splitRoomName = roomName.split(' ');
  let groupName;
  if (splitRoomName.length === 1) {
    const firstLetterRoomName = splitRoomName[0][0]?.toUpperCase();
    groupName = firstLetterRoomName;
  } else if (splitRoomName.length > 1) {
    const firstOne = splitRoomName[0][0]?.toUpperCase();
    const secondOne = splitRoomName[1][0]?.toUpperCase();
    const joinRoomName = `${firstOne}${secondOne}`;
    groupName = joinRoomName;
  }
  const initialColor = letterColors[groupName[0]] || '#FF5733';
  const initialTextColor = textColors[groupName[0]] || '#FF5733';
  return (
    <li className={classes.listItemMainContainer} onClick={()=>roomUserClicked(userDetails)}>
      <div className={classes.groupNameIcon} style={{ backgroundColor: initialColor }}>
        <span style={{ color: initialTextColor }}>{groupName || 'hi'}</span>
        {/* <div 
        className={`${classes.statusIndicator} ${!isOnline ? classes.offline : ''}`}
      /> */}
      </div>
      <div className={classes.roomItemMessageContaine}>
        <span className={classes.RoomItemName}>
          {userDetails?.user_details.user_name ?? 'Unknown'}
        </span>
      </div>
      <div className={classes.lastMessageTimeStamp}>
        {userDetails.room_role === 'Admin' ? (
          <span className={classes.lastMessageUnreadTime}>{userDetails.room_role}</span>
        ) : (
          ''
        )}

        {/* {
        room.unread_message_count >0 ?  <span className={room.unread_message_count>0 ?`${classes.roomMessageCount}`:''}>{room.unread_message_count}</span>:null
      }
      */}
      </div>
    </li>
  );
};

export default GroupUsers;
