import { combineReducers } from "redux";
import CustomersSlice from "./Slices/CustomersSlice";
import DesignationsList from "./Slices/DesignationsListSlice";
import LoginSlice from "./Slices/LoginSlice";
import WeHouseUserSlice from "./Slices/WeHouseUserSlice";
import TurnkeyPartnersListSlice from "./Slices/turnkeyPartnersListSlice";
import YearsOfExperinceListSlice from "./Slices/YearsOfExperinceListSlice";
import RolesListSLice from "./Slices/RolesListSLice";
import architectPartnersListSlice from "./Slices/architectPartnersListSlice";
import interiorPartnersListSlice from "./Slices/interiorPartnersListSlice";
import getStatusListSlice from "./Slices/getStatusListSlice";
import getSkillTypeSlice from "./Slices/getSkillTypeSlice";
import getCategoriesListSlice from "./Slices/getCategoriesListSlice";
import getEducationalListSLice from "./Slices/getEducationalListSLice";
import getPartnerManagerListSLice from "./Slices/getPartnerManagerListSLice";
import dropDownListSlice from "./Slices/dropDownListSlice";
import ChatServiceReduxSlice from "./Slices/ChatServiceReduxSlice";
import socketSlice from "./Slices/socketSlice";

const rootReducer=combineReducers({
    Login:LoginSlice,
    Customers:CustomersSlice,
    WeHouseuser:WeHouseUserSlice,
    DesignationList:DesignationsList,
    TurnkeyPartners:TurnkeyPartnersListSlice,
    YearsOfExperience:YearsOfExperinceListSlice,
    RolesList:RolesListSLice,
    ArchitectPartnersList:architectPartnersListSlice,
    InteriorPartnersList:interiorPartnersListSlice,
    getStatusListSlice:getStatusListSlice,
    getSkillTypeSlice:getSkillTypeSlice,
    getCategoriesListSlice:getCategoriesListSlice,
    getEducationalListSLice:getEducationalListSLice,
    getPartnerManagerListSLice:getPartnerManagerListSLice,
    dropDownListSlice:dropDownListSlice,
    ChatServiceReduxSlice:ChatServiceReduxSlice,
    socket:socketSlice,


})

export default  rootReducer;