import { gql } from '@apollo/client';

export const TYPING_NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnTypingNotification($roomId: String!) {
    onTypingNotification(roomId: $roomId) {
      userId
      roomId
      isTyping
    }
  }
`;