
import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import './dashboard.css';
import { Pie } from "react-chartjs-2";
import D3Funnel from 'd3-funnel';
import * as d3 from "d3";
import { salesCoordinatorDashboardServices } from '../../../../Service/Dashboard/SalesCoordinator/getDashboardLeadsCount';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants'
import AppointmentsDetails from '../MyAppointments/Appointments'
import DatePicker from '../../../../Components/CommonComponents/dateRangePicker';
import { myLeadsAppointments } from '../../../../Service/MyLeadsService';
import { Popover } from 'antd';

import ActivityLog from './ActivityLog';
import { addDays, subDays } from 'date-fns';
import moment from 'moment';
import { faLoveseat } from '@fortawesome/pro-duotone-svg-icons';
import AdvancedFilter from './advancedFilter2';



const Dashboard = () => {
  const [leadsList, setLeadsList] = useState([]);
  const [funnelLeadsList, setFunnelLeadsList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [funnel, setFunnel] = useState([]);
  const [selectDate, setSelectDate] = useState(false);
  const [selectRange, setSelectRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    }
  ])
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [showActivityLog, setShowActivityLog] = useState(false);
  const [select, setSelect] = useState('CrmDashboard');
  const [dailyLeads, setDailyLeads] = useState([]);
  const [leadsByWise, setLeadsByWise] = useState("");
  const [check, setCheck] = useState('crm');
  const [salesExecutivesList, setSalesExecutivesList] = useState([]);
  // const [salesExecutiveId, setSalesExecutiveId] = useState(null);
  const [showModal, setShowModal] = useState(false)
  // select calendar Function:
  const dateToggleHandler = () => {
    setSelectDate(!selectDate)
    setCheck('date')
  }
 const [advancedFilterData, setAdvancedFilterData] = useState({
   salesExecutive :null
 })

  // const startDate = selectRange[0].startDate.toString().slice(4, 15)
  // const endDate = selectRange[0].endDate.toString().slice(4, 15)

  const fromDate = moment(selectRange[0].startDate).format("YYYY-MMM-DD");
  const toDate = moment(selectRange[0].endDate).format("YYYY-MMM-DD");

  console.log("selectDate:", fromDate);


  // const selecthandler = (event) =>{
  //    setSelect(event.target.value)
  // }



  /*   // Pie chart data: */
  ChartJS.register(ArcElement, Tooltip, Legend);

  const Piedata = {
    labels: Object.keys(leadsList),
    datasets: [
      {
        label: "Something",
        data: Object.values(leadsList),
        backgroundColor: [
          "#fd2776",
          "#00deff",
          "#777777",
          "#1ad99f",
          "#559c11",
          "#5a87ff",
          "#ff3e00",
          "#33d0a0",
        ],
        borderColor: [
          "#fd2776",
          "#1ad99f",
          "#777777",
          "#1ad99f",
          "#559c11",
          "#5a87ff",
          "#ff3e00",
          "#33d0a0",
        ],
        borderWidth: 1
      }
    ]
  }




  // Funnel Chart :

  //  const nivo = ["#fd2776","#00deff","#777777","#1ad99f","#559c11","#5a87ff","#ff3e00","#33d0a0"]
  const nivo = ["#fd2776", "#00deff", "#777777", "#1ad99f"]


  const chartColorScale = d3.scaleQuantize().domain([0, leadsData.length]).range(nivo)
  const totalData = leadsData.reduce((a, c) => {
    return a + c.value;
  }, 0)

  console.log("leadslist:", totalData);

  const options = {
    block: {
      dynamicHeight: true,
      minHeight: 99,
    },
    chart: {
      animate: 20,
      curve: {
        enabled: true,
        height: 18,
        shade: -0.0
      },
      bottomPinch: 3,
      height: 400,
      width: 500,
    },

    tooltip: {
      enabled: true,
      format: (l, f, color) => {
        const percentage = ((f / totalData) * 100).toFixed(1);
        return `${l}: ${f}`;
      }
    },

    label: {
      fill: "#FFFFFF",
      fontFamily: "Open Sans",
      fontSize: 13
    }
  };


  /*    funnelHandler(); */
  const funnelHandler = (data) => {
    const chart = new D3Funnel("#funnel");

    const FunnelData = data?.map((row, index) => {
      return [
        row.label,
        row.value,
        d3.color(chartColorScale(index)).hex()
      ];
    })

    chart.draw((FunnelData ? FunnelData : [{ label: "", value: 0 }]), options)
  }

  //  console.log("funnel data :",FunnelData);

  /*   Apply Filter Function: */
  const applyFilter = () => {
    getLeadsAndAppointmentsService();
    setSelectDate(false)
    setCheck("filterBtn")

  }

  const settingsHandler = () => {
    setCheck('setting')
  }

  const dateDifferenceInDays = () => {
    const date1 = new Date(fromDate)
    const date2 = new Date(toDate)
    return Math.floor(
      (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
        Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
      (1000 * 60 * 60 * 24)
    )
  }





  /*   get leads and appointments list APi : */
  const getLeadsAndAppointmentsService = async (filter) => {
     
    if (select === "CrmDashboard") {

      let data = {
        fromDate: fromDate,
        toDate: toDate,
        userId:advancedFilterData.salesExecutive || userId 
      }

      const leadsResponse = await salesCoordinatorDashboardServices.getleadsandAppointmentsByDateFilter(data);
      console.log("getLeadsAndAppointmentsService:", leadsResponse);
      if (leadsResponse.status === "success" && leadsResponse.data.getLeadsDate) {
         filter && setShowModal(false)
        setLeadsList(leadsResponse.data.leadsDashboardData[0]);
        setFunnelLeadsList(leadsResponse.data.leadsDashboardFunnelData[0])
        setAppointmentsData(leadsResponse.data.leadsAppoinmentDetails)
        const labels = leadsResponse.data.leadsDashboardFunnelData[0] ? Object.keys(leadsResponse.data.leadsDashboardFunnelData[0]) : "";
        // console.log("labels:", labels);

        const values = leadsResponse.data.leadsDashboardFunnelData[0] ? Object.values(leadsResponse.data.leadsDashboardFunnelData[0]) : 0;
        // console.log("values:", values);

        const data = labels?.map((item, index) => {
          return (
            {
              label: item,
              value: values[index]
            }
          )
        });

        console.log("data", data);
        // setFunnel(data);
        funnelHandler(data);
        setLeadsData(data)
      }
    } else if (select === "ActivityLog") {

      let dateranges = {
        fromDate: fromDate,
        toDate: toDate,
        userId:advancedFilterData.salesExecutive  || userId 
      }

      const response = await salesCoordinatorDashboardServices.getDailyLeadsDateFilter(dateranges);
      console.log("daily leads response ::", response);
      if (response.status === 'success' && response.data.isDailyLeadsData) {
        filter && setShowModal(false)
        if (dateDifferenceInDays() > 31) {
          setDailyLeads(response.data.leadsByContinuousDateByYearly)
          setLeadsByWise('yearly')
        } else {
          setDailyLeads(response.data.leadsByContinuousDate)
          setLeadsByWise('daily')
        }

        const leadsResponse = await salesCoordinatorDashboardServices.getleadsandAppointmentsByDateFilter(dateranges);
        console.log("getLeadsAndAppointmentsService:", leadsResponse);
        if (leadsResponse.status === "success" && leadsResponse.data.getLeadsDate) {
          setLeadsList(leadsResponse.data.leadsDashboardData[0]);

        }
      } else {
        setDailyLeads([])
      }

    }

  }

  console.log("leadsList:", leadsList);
  console.log("leadsData::", leadsData);
  console.log("dailyLeads::", dailyLeads);

  const userId = Number(window.localStorage.getItem("user_Id"))

  // function for select crm dashboard or activity log:
  const selectHandler = (e) => {
    setSelect(e.target.value)
    setCheck('crm')
  }


  const seSelectHandler = (value) => {
    console.log(`selected ${value}`);     
};

  const getSalesExecutivesList = async  ()=>{
        try {
          let data = {
            userId : Number(window.localStorage.getItem('user_Id'))
          }
          const response = await salesCoordinatorDashboardServices.getSalesExecutiveList(data);
          console.log(response);
          if(response?.status === 'success' && response.data.isSalesexecutivesList){
            setSalesExecutivesList(response.data.salesExecutivesList)
          }else{
            setSalesExecutivesList([])
          }
           

        } catch (error) {
          console.log(error);
        }
  }
console.log('salesExecutivesList:',salesExecutivesList.length);
console.log('salesExecutive Id:',advancedFilterData.salesExecutive);


const resetFilter=()=>{
  setAdvancedFilterData(data => ({...data , salesExecutive : null}))
  setShowModal(false)
} 


const modalHandler=(newOpen)=>{
  setShowModal(newOpen)
}

  useEffect(() => { 
      getLeadsAndAppointmentsService();
      getSalesExecutivesList();
    if (funnel?.length) {
      funnelHandler();
    }
    // getScheduleMeetings()
  }, [totalData,select,showModal])
  console.log('select::', select);

  return (
    <div>
      <div className="mainContent">
        <div className="contentHeader">
          {select === "ActivityLog" ? <span className="headerTitle">Activity Log</span>
            : <span className="headerTitle">CRM Dashboard</span>
          }
        </div>
        <div className="whContentBlock">
          <div className="bodyHeader">
            <select className={`selectBtn ${check === 'crm' ? "ActiveBtn" : ""}`} value={select} onChange={selectHandler}>
              <option value="CrmDashboard">CRM Dashboard</option>
              <option value="ActivityLog">Activity Log</option>
            </select>

            <div className='groupBtns'>
              <button className={`btn dateSelector dashboradDateSelector ${check === "date" ? "ActiveBtn" : ""}`}>
                <input type="button"
                  value={fromDate + " - " + toDate}
                  className="date-selector"
                  onClick={dateToggleHandler}
                />
                <img src={imageAssets.calendar} onClick={dateToggleHandler} className="calendarIcon" />
                <div className="calendar">
                  {selectDate && <DatePicker date={selectRange} handler={setSelectRange} />}
                </div>
              </button>
              <button className={`btn applyFilterBtn`} onClick={applyFilter} >Apply Filter</button>
              <Popover  overlayClassName="customPopover" 
               placement="bottomLeft" 
               trigger="click"     
               visible={showModal}     
               onVisibleChange={modalHandler}  
              content={
              <AdvancedFilter 
              advancedFilterData={advancedFilterData}
              setAdvancedFilterData={setAdvancedFilterData}
              salesExecutivesList={salesExecutivesList}
              seSelectHandler={seSelectHandler}
              resetFilter={resetFilter}
              applyFilter={getLeadsAndAppointmentsService}
              />
              } 
              title="Advanced Filter" >
                <button type="button" className={`btn  applyFilterBtn ${check === "setting" ? "ActiveIcon" : ""}`} onClick={settingsHandler} >
                  <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="" height="20"><path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" /><path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" /><path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" /></svg>
                </button>
              </Popover>

            </div>
          </div>

          <div className='contentBody detail'>
            <div class="row cardsLayout">
              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Raw Leads</span>
                    <span className="cardLeads">{leadsList.Raw}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Potential Leads</span>
                    <span className="cardLeads">{leadsList.Allocated}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Cold Leads</span>
                    <span className="cardLeads">{leadsList.Cold}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Hot Leads</span>
                    <span className="cardLeads">{leadsList.Hot}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Future Leads</span>
                    <span className="cardLeads">{leadsList.Future}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Discarded Leads</span>
                    <span className="cardLeads">{leadsList.Discarded}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Lost Leads</span>
                    <span className="cardLeads">{leadsList.Lost}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-3 mb-4">
                <div className='card crdItem'>
                  <div className="cardInfo">
                    <span className="cardTitle">Won Leads</span>
                    <span className="cardLeads">{leadsList.Won}</span>
                    <span className="cardFooter">
                    </span>
                  </div>
                  <div className="cardIcon">
                    <div className="iconCirclBg">
                      <i class="smIcon"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {select === "ActivityLog" ? <ActivityLog leadsByWise={leadsByWise}
             timelineLeads={dailyLeads} startDate={fromDate} endDate={toDate}
             /> : <div className='row'>
              <div className='col-md-7'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className="dashboardCard">
                      <div className="title">
                        Leads Funnel chart
                      </div>
                      <div id="funnel" className="funnel"></div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className="dashboardCard">
                      <div className="title">
                        Leads Pie chart
                      </div>
                      <div className="pieChart">
                        <Pie data={Piedata} options={""} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;