import { createSlice } from "@reduxjs/toolkit";

const socketSlice = createSlice({
  name: "socket",
  initialState: {
    data: null,
    error: null,
  },
  reducers: {
    setSocketData: (state, action) => {
      state.data = action.payload;
    },
    setSocketError: (state, action) => {
      state.error = action.payload;
    },
    
    
  },
});

export const { setSocketData, setSocketError } = socketSlice.actions;

export default socketSlice.reducer;