import React, { useEffect, useState, useCallback } from "react";
import { AdaptiveCard } from "adaptivecards";
import { marked } from "marked";
import type { ChatAdaptiveCardType } from "./ChatAdaptiveCard.type";

const AdaptiveCardRenderer: React.FC<ChatAdaptiveCardType & { messageId: string }> = ({ 
  jsonData,
  messageId 
}) => {
  const [parsedData, setParsedData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const containerId = `adaptive-card-${messageId}`; // Unique ID for each card

  // Parse JSON once when component mounts or jsonData changes
  useEffect(() => {
    try {
      const parsed = JSON.parse(jsonData);
      setParsedData(parsed);
      setError(null);
    } catch (error) {
      console.error("Invalid JSON data:", error);
      setError("Unable to parse card data");
    }
  }, [jsonData]);

  // Configure markdown processing once
  useEffect(() => {
    AdaptiveCard.onProcessMarkdown = (text, result) => {
      try {
        result.outputHtml = marked.parse(text);
        result.didProcess = true;
      } catch (error) {
        console.error("Markdown processing failed:", error);
        result.outputHtml = text;
        result.didProcess = false;
      }
    };
  }, []);

  const renderAdaptiveCard = useCallback(() => {
    if (!parsedData) return;

    try {
      const adaptiveCard = new AdaptiveCard();
      adaptiveCard.parse(parsedData);
      
      adaptiveCard.onExecuteAction = (action) => {
        console.log("Action executed:", action.title);
      };

      const container = document.getElementById(containerId);
      if (container) {
        // Clear any existing content
        container.innerHTML = '';
        const renderedCard = adaptiveCard.render();
        if (renderedCard) {
          container.appendChild(renderedCard);
        }
      }
    } catch (error) {
      console.error("Error rendering Adaptive Card:", error);
      setError("Error rendering card");
    }
  }, [parsedData, containerId]);

  // Render card only when parsedData is available
  useEffect(() => {
    if (parsedData) {
      renderAdaptiveCard();
    }
  }, [parsedData, renderAdaptiveCard]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="adaptive-cards-page-chat">
      <div className="adaptive-cards-grid-chat">
        <div id={containerId} className="adaptive-card-container-chat" />
      </div>
    </div>
  );
};

export default AdaptiveCardRenderer;