import React from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { useStyles } from './RoomOptions.style';
import { useState,useRef, useEffect } from "react";
// import RoomInfoScreen from "../RoomInfoScreen/RoomInfoScreen";

import { RoomOptionProps } from "./RoomOptions.type";
import { useDispatch, useSelector } from "react-redux";
import { toggleRoomDescriptionClicked,chatDetailClicked1, chatOpenClicked,chatButtonClicked} from  '../../../../../Features/Slices/ChatServiceReduxSlice'



const RoomOptions:React.FC<RoomOptionProps>=({
    // setIsRoomDescription
    isChatBtnClicked,
    setIsChatBtnClicked,
    isChatDetailClicked,
    setIsChatDetailClicked

})=>{
    const {classes}=useStyles()
    const dispatch = useDispatch();
    const [isDropDownOpen,setIsDropDownOpen]=useState(false)
    const [isRoomInfoButtonClicked,setRoomInfoButtonClicked]=useState(false)
    const dropdownRef=useRef<HTMLDivElement>(null);

    // const isChatBtnClicked=useSelector((state:any)=>state.ChatServiceReduxSlice.isChatBtnClicked)

    const toggleDropDown=()=>{
        setIsDropDownOpen((prev)=>!prev)
    }
    const handleClickOutside=(event:MouseEvent)=>{
        if(dropdownRef.current && !dropdownRef.current.contains(event.target as Node)){
            setIsDropDownOpen(false)
        }
    }
    const handleRoomInfo=()=>{
        setRoomInfoButtonClicked((prev)=>!prev)
        setIsDropDownOpen((prev)=>!prev)
        dispatch(toggleRoomDescriptionClicked());
    }

const handleChatRoom=()=>{
    setIsChatDetailClicked(false)
}

const handleChatClose=()=>{
   setIsChatBtnClicked(false)
    
}

    useEffect(()=>{
        if(isDropDownOpen){
            document.addEventListener("mousedown",handleClickOutside)
        }else{
            document.removeEventListener("mousedown",handleClickOutside)
        }
    })

    // const handleChatClose=()=>{

    //     dispatch(chatOpenClicked())
    //   }


    return (
        <div>

        
        <div ref={dropdownRef}>
            <BsThreeDotsVertical  className={isDropDownOpen ? classes.threeDotsColor :classes.threeDots} onClick={toggleDropDown}/>
            {isDropDownOpen && (<div className={classes.dropDown}>
                    <ul className={classes.dropDown}>
                    <li className={classes.dropDownItem} onClick={handleRoomInfo}>
                    Room info
                    </li>
                    <li className={classes.dropDownItem}
                     style={{cursor:"not-allowed"}}
                    // onClick={handleChatRoom}
                     >
                    Close chat
                    </li>
                    <li className={classes.dropDownItem} style={{cursor:"not-allowed"}}>
                    Clear chat
                    </li>
                    <li className={classes.dropDownItem} 
                    style={{cursor:"not-allowed"}}
                    // onClick={handleChatClose}
                   >
                    Exit 
                    </li>
                    </ul>
            </div>)}
        </div>

        </div>
    )
}

export default RoomOptions;