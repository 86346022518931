import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isRoomDescriptionClicked: any }>()(
  (
    _
    // isRoomDescriptionClicked
  ) => ({
    expandMainFullScreenContainer: {
      position: 'absolute',
      left: 0,
      // height: 'calc(100vh - 50px)',
      backgroundColor: '#fff',
      width: '100vw',
      zIndex: 999,
      display: 'flex',
      height: '100vh',
    },
    emptyStateContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#fafafa', // Centers horizontally
      alignItems: 'center', // Centers vertically
      flexGrow: 1, // Ensures it occupies the full height and width
      fontSize: '1.5rem', // Adjust font size for better visuals
      fontWeight: 'bold', // Makes the text bold
      color: '#555', // Optional: Gives a subtle grey color
      width: '100%',
      // height:"100vh"
    }
  })
);
