import { gql } from '@apollo/client';

export const GET_USER_ROOMS_BY_SEARCH = gql`
  query MyQuery2($user_id: ID!, $userSearchInput: String!) {
    getUserRoomsBySearch(
      input: { user_id: $user_id }
      userSearchInput: $userSearchInput
    ) {
        userRooms {
        unread_message_count
        room_details {
            lastMessage {
            created_date
            payload
            }
            name
            type
        }
        room_id
        }
        user_id
        user_name
    }
  }
`;
