const handleSocketData = (socketData, setNotifications) => {
  console.log(socketData, "socketData");

  const userId = Number(window.localStorage.getItem("user_Id"));
  console.log(userId, "userId");

  if (socketData?.recipient_ids?.includes(userId)) {
    console.log("New notification received via socket", socketData);

    let newNotification = {};

    if (socketData.entity_type_id === 2) {
      // **Project Allocation Notification**
      newNotification = {
        title: `Project ${socketData.project_name}`,
        description: `Allocated to ${socketData.pmo_name}, ${socketData.pm_name}, ${socketData.pc_name}, and ${socketData.customer_name}.`,
        budget: `Budget: ${socketData.budget}`,
        timeline: `Timeline: ${socketData.start_date} - ${socketData.end_date}`,
      };
    } else if (socketData.entity_type_id === 3) {
      // **Task Status Notification**
      if (socketData._task_status_name === "In Progress") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `In-progress of SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is Approved by ${socketData._actor_name}`,
        };
      } else if (socketData._task_status_name === "In-progress Requested") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} is in In-progress.`,
        };
       } else if(socketData._task_status_name === "In Progress - Approved by PM") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `In-progress Request of SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is Approved by ${socketData.pm_name}.`,
        };
       }
      
      else if(socketData._task_status_name === "Completed Requested") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `${socketData._task_status_name} by ${socketData.pc_name} for SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name}`,
        };
      } else if(socketData._task_status_name === "Completed") {
        let status = socketData._task_status_name;
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: ` SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is ${status}`,
        };
      } else if(socketData._task_status_name === "Completed - Approved by PM"){
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `Completed Request of SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is Approved by ${socketData.pm_name}`,
        };
      }
    }

    if (Object.keys(newNotification).length > 0) {
      newNotification = {
        ...newNotification,
        entityTypeId: socketData.entity_type_id,
        notificationId: socketData.notification_id,
        read: socketData.isRead,
      };

      setNotifications((prev) => {
        // If the notificationId doesn't exist in the prev list, add the new notification
        if (!prev.some((notif) => notif.notificationId === newNotification.notificationId)) {
          return [...prev, newNotification];
        }
        return prev; // Return prev if the notification is already in the list
      });

    
    }
  }
};

export default handleSocketData;
