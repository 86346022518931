import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useSubscription, useLazyQuery} from '@apollo/client';
import { GET_ROOM_MESSAGES } from '../../queries/getRoomMessages';
import { CLEAR_MESSAGE_COUNT } from '../../queries/clearUnReadMessageCount';
import { CREATE_MESSAGE_SUBSCRIPTION } from '../../subscriptions/onCreateMessage';

import { Message, ChatRoomDetailProps, Attachment } from './ChatRoomDetail.types';
import { useChatStyles } from './ChatRoomDetail.style';
import ProfileImage from '../../../assets/activeUser.png'
import userProfileImage from '../../../assets/inactiveChatUser.png';
import ScrollToBottom from 'react-scroll-to-bottom';
import SendMessageBox from '../SendMessageInputBox/SendMessageBox';
import { ChatDoubleTicks, ChatFullScreenIcon } from '../../../Icons/icons';
import { IoMdArrowRoundBack } from "react-icons/io";
import RoomOptions from '../RoomOptions/RoomOptions';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ROOM_DETAILS_BY_ID } from '../../queries/getRoomDetailsById';
import { TYPING_NOTIFICATION_SUBSCRIPTION } from '../../subscriptions/typingNotificationSubsciption';
import { chatDetailClicked1, toggleRoomDescriptionClicked,chatOpenClicked, setchatRoomDetails,chatButtonClicked ,setChatUnreadMessages} from '../../../../../Features/Slices/ChatServiceReduxSlice'
import AdaptiveCardRenderer from '../ChatAdaptiveCard/ChatAdaptiveCard';
import ChatAttachmentFiles from '../ChatAttachmentFiles/ChatAttachmentFiles';
import ImagePreviewModal from '../ChatImagePreview/ChatImagePreview';

import { useUpdateMessageStatus } from '../../chatHooks/useUpdateMessageStatuss';
import { MESSAGE_STATUS_SUBSCRIPTION } from '../../subscriptions/onUpdateMessage';
export const ChatRoomDetail: React.FC<ChatRoomDetailProps> = ({
  handleExpandChat,
  isExpandChat,
  isChatBtnClicked,
  setIsChatBtnClicked,
  isChatDetailClicked,
  setIsChatDetailClicked,
  setRoomDetails,
  roomDetails,
 

}) => {
  console.log(isChatDetailClicked,"isChatDetailClicked")
  const [messages, setMessages] = useState<Message[]>([]);
  const [roomChatId, setRoomChatId] = useState('');
  const [currentRoomdetails, setCurrentRoomDetails] = useState<any>(null);
  const [userAllDetails, setUserAllDetails] = useState<any>(null);
  const [typingUsers, setTypingUsers] = useState<string[]>([]);
  const [logginedUser, setLogginedUser] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState(false);
  // const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [imageUrls, setImageUrls] =useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPreview, setShowPreview] = useState(false);

  const [currentUsersCountInRoom, setCurrentUsersCountInRoom] = useState<number>(0)

  const chatRoomId = useSelector((state: any) => state.ChatServiceReduxSlice.chatRoomId);
  const chatRoomDetails = useSelector((state: any) => state.ChatServiceReduxSlice.chatRoomDetails);
  const userDetialsFromRedux = useSelector((state: any) => state.ChatServiceReduxSlice.userDetailsInRedux);
  // const isChatBtnClicked=useSelector((state:any)=>state.ChatServiceReduxSlice.isChatBtnClicked)
  const [roomDetailsById ] = useLazyQuery(GET_ROOM_DETAILS_BY_ID)
  const { updateMessageStatus} = useUpdateMessageStatus();
  console.log("chatRoomId",chatRoomId);
  const getRoomWithCurrecntUsers = async (currentRoom: { room_id: any; }) => {
    try{
      const response = await roomDetailsById(
        {variables: { roomId: currentRoom.room_id}}
      )
      console.log("room id is ===========> ", currentRoom.room_id)
      console.log("room with users from chatRoomdetails ====> ", response.data.getRoomDetails.associatedUsers)
      setCurrentUsersCountInRoom(response.data?.getRoomDetails?.associatedUsers?.length )
    }catch(error){
      console.log("Error while getting roomUsers ==> ", error)
    }
  }
  
 

  useEffect(() => {
    if (chatRoomId) {
      const currentRoom = chatRoomDetails.find((room: any) => room.room_id === chatRoomId);
      //console.log("currentRoom from chatRoomDetails ==========> ", currentRoom)
      getRoomWithCurrecntUsers(currentRoom)
      setUserAllDetails(userDetialsFromRedux);
      setCurrentRoomDetails(currentRoom);
      setRoomChatId(chatRoomId);
    }
  }, [chatRoomId, chatRoomDetails]);

 const isRoomDescriptionClicked=useSelector((state:any)=> state.ChatServiceReduxSlice.isRoomDescriptionClicked)

 const dispatch=useDispatch()






 console.log("isRoomDescriptionClicked",isRoomDescriptionClicked)
console.log("userdetails",userDetialsFromRedux.user_id )
console.log("roomId",roomChatId)

  const { data, refetch } = useQuery(GET_ROOM_MESSAGES, {
    variables: { room_id: roomChatId,user_id:userDetialsFromRedux.user_id },
    skip: !roomChatId
  });

  const [clearMessageCount] = useMutation(CLEAR_MESSAGE_COUNT);
  const { classes } = useChatStyles({ isExpandChat , isRoomDescriptionClicked });

  useEffect(() => {
    
    if (data && data.getAllMessagesByRoom && data.getAllMessagesByRoom.Messages){

      const allMessages = [...data.getAllMessagesByRoom.Messages]
      allMessages.map(async (eachMessage) => {
        if (userDetialsFromRedux.user_id !== eachMessage.sender_id && chatRoomId && !eachMessage.read_by_user_ids?.includes(userDetialsFromRedux.user_id)){
          //console.log("check users read or not ====> ", eachMessage.sender_id, "reciver ==> ", userDetialsFromRedux.user_id, "is read ==> ", !eachMessage.read_by_user_ids?.includes(userDetialsFromRedux.user_id), "ovral ===> ", userDetialsFromRedux.user_id !== eachMessage.sender_id && chatRoomId && !eachMessage.read_by_user_ids?.includes(userDetialsFromRedux.user_id))
          try {
              await updateMessageStatus(
              chatRoomId,
              userDetialsFromRedux.user_id,
              eachMessage.message_id
            );
            //console.log("userDetialsFromRedux.user_id => ", userDetialsFromRedux.user_id)
            //console.log('Status updated from chatRoomDetails =======> :', result);
          } catch (err: any) {
            console.error('Error chatRoomDetails =======> :', err.message);
          }
        }
        
      })

    }
    refetch()
    
  }, [chatRoomId, data, refetch, updateMessageStatus, userDetialsFromRedux.user_id])

  useEffect(() => {
    if (data && data.getAllMessagesByRoom && data.getAllMessagesByRoom.Messages) {
      setMessages([...data.getAllMessagesByRoom.Messages]);
    }
  }, [data]);

  const { data: subscriptionData } = useSubscription(CREATE_MESSAGE_SUBSCRIPTION, {
    variables: { room_id: roomChatId }
  });

  const { data: messageStatusSubscription } = useSubscription(MESSAGE_STATUS_SUBSCRIPTION, {
      // onSubscriptionData: ({ subscriptionData }) => {
      //   console.log('Subscription data received:', subscriptionData);
      // },
      // onError: (error) => {
      //   console.error('Subscription error details: --->', error);
      // }
    }
  );
  
  useEffect(() => {
    if (messageStatusSubscription && messageStatusSubscription.onUpdateMessageStatus){
      refetch();
    }
    if (subscriptionData && subscriptionData.onCreateMessage) {
      refetch();
    }
  }, [subscriptionData, refetch, messageStatusSubscription]);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const { data: typeNotification } = useSubscription(TYPING_NOTIFICATION_SUBSCRIPTION, {
    variables: { roomId: currentRoomdetails?.room_id } 
  });
  useEffect(() => {
    if (typeNotification && typeNotification.onTypingNotification) {
      const { userId, isTyping } = typeNotification.onTypingNotification;
      setLogginedUser(userAllDetails.user_name == userId);
      setIsTyping(true);
      if (userId === userAllDetails?.user_name) {
        // setLogginedUser(true);
        setIsTyping(isTyping);
      } else {
        setTypingUsers((prev) => {
          if (isTyping) {
            // Add user if not already in the list
            return prev.includes(userId) ? prev : [...prev, userId];
          } else {
            // Remove user if they stopped typing
            return prev.filter((user) => user !== userId);
          }
        });
      }
     
    }
  }, [typeNotification, userAllDetails?.user_name]);

  const typingMessage =
    typingUsers.length > 0
    ? `${
      typingUsers.length > 2
        ? `${typingUsers.slice(0, 2).map(user => user.split(' ')[0]).join(', ')}, and ${
            typingUsers.length - 2
          } more`
        : typingUsers.length > 1
        ? typingUsers.map(user => user.split(' ')[0]).join(', ')
        : typingUsers[0]
    } ${typingUsers.length > 1 ? 'are' : 'is'} typing...`
      : null;
  // Scroll to the bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (!userAllDetails?.user_id || !roomChatId) return;

  clearMessageCount({
    variables: {
      room_id: roomChatId,
      user_id: userAllDetails.user_id
    }
  }).catch((err) => console.error('Mutation Error:', err.message));
  }, [subscriptionData, data]);

  const getFormattedDate = (dateString: string): string => {
    const messageDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (
      messageDate.getDate() === today.getDate() &&
      messageDate.getMonth() === today.getMonth() &&
      messageDate.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    }

    if (
      messageDate.getDate() === yesterday.getDate() &&
      messageDate.getMonth() === yesterday.getMonth() &&
      messageDate.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }
    return messageDate.toLocaleDateString();
  };

  type GroupedMessage = {
    date: string;
    messages: Message[];
  };

  const groupMessagesByDate = (messages: Message[]) => {
    const groupedMessages: GroupedMessage[] = [];
    let currentDate: string | null = null;

    messages.forEach((msg) => {
      const formattedDate = getFormattedDate(msg.created_date);

      // If the formatted date is different from the previous one, add a separator
      if (formattedDate !== currentDate) {
        groupedMessages.push({ date: formattedDate, messages: [] });
        currentDate = formattedDate;
      }

      groupedMessages[groupedMessages.length - 1].messages.push(msg);
    });

    return groupedMessages;
  };

  // const handleImageClick = (imageUrl: string) => {
  //   setPreviewImage(imageUrl);
  // };

  const formattedMessages = groupMessagesByDate(messages);

  const handleExpand=()=>{
    // dispatch(chatDetailClicked1())
    setIsChatDetailClicked(false)
    handleExpandChat();
   
    if(isRoomDescriptionClicked){
      dispatch(toggleRoomDescriptionClicked())

    }


  }
  const renderMessageContent = (msg: Message) => {
    if (msg.message_type === 'adaptive-card') {
      return (
        <AdaptiveCardRenderer 
          jsonData={msg.payload} 
          messageId={msg.message_id}
        />
      );
    } else if (msg.message_type === 'file') {
      return (
        <div>
           {msg.payload && <div>{msg.payload}</div>}
        <div className={classes.chatAttachmentImage}>
        {msg.attachments && Array.isArray(msg.attachments) && msg.attachments.map((attachment, index) => {
          const parsedAttachment = JSON.parse(attachment);
          const isImage = parsedAttachment.mime_type?.startsWith('image/');
          
          return (
            <div 
              key={index}
              onClick={() => {
                if (isImage) {
                  const images = msg.attachments
                    .map((att:any) => JSON.parse(att))
                    .filter((att:any) => att.mime_type?.startsWith('image/'))
                    .map((att:any) => att.url);
                    
                  if (images.length > 0) {
                    setImageUrls(images);
                    // Set current index to the clicked image
                    const clickedImageIndex = images.findIndex((url:any) => url === parsedAttachment.url);
                    setCurrentIndex(clickedImageIndex >= 0 ? clickedImageIndex : 0);
                    setShowPreview(true);
                  }
                }
              }}
            >
              <ChatAttachmentFiles file={[attachment]} />
            </div>
          );
        })}
      </div>
      </div>
        
      );
    } else {
      return msg.payload;
    }
  };
  
  return (
    <div className={classes.chatContainer}>
      <header className={classes.chatUsernameHeader}>
        <div className={classes.chatUsername}>
          <img src={ProfileImage} alt="ProfileImage" className={classes.chatProfileIcon} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{margin:"0px",fontSize:"inherit",fontWeight:"inherit"}}>{currentRoomdetails?.room_details?.name}</h3>
            {isTyping && !logginedUser ? <span className={classes.typingStatus}>{typingMessage}</span> : null}
          </div>
        </div>

    
  

        <div style={{ display: 'flex', alignItems:'center'}}>
          {/* {!isExpandChat &&  */}
                  <div className={classes.chatBackButton}
                  style={{cursor:"pointer"}}
                  onClick={() => {
                    console.log(isChatDetailClicked, "detailinggggggg");
                    setIsChatDetailClicked((prevState)=>!prevState);
                    setRoomDetails(null)
              
                  
                }}
                >
          
          <IoMdArrowRoundBack/>
          
          </div>
          {/* } */}

        <div
          className={classes.chatFullScreen}
          onClick={()=>{
            handleExpand()

          }
          }>
          <ChatFullScreenIcon />
        </div>
        <RoomOptions
        isChatDetailClicked={isChatDetailClicked}
        setIsChatDetailClicked={setIsChatDetailClicked}
          isChatBtnClicked={isChatBtnClicked}
          setIsChatBtnClicked={setIsChatBtnClicked}
        
        />
        </div>
      </header>

      <div className={classes.chatMessagesContainer}>
    
        <ScrollToBottom className="messagesContainer">
          {formattedMessages.map((group, index) => (
            <div key={index}>
              <div className={classes.dateSeparator}>
                <span className={classes.dateStyle}>{group.date}</span>
              </div>
              {group.messages.map((msg) => (
                
                <div
                  key={msg.message_id}
                  className={`${classes.chatMessage} ${
                    msg.sender_id === userAllDetails?.user_id
                      ? classes.rightAligned
                      : classes.leftAligned
                  }`}>
                  {msg.sender_id !== userAllDetails?.user_id &&
                    currentRoomdetails.room_details.type === 'group' && (
                      <img src={userProfileImage} alt="user_profile_icon" />
                    )}

                  <div
                    className={`${
                      msg.sender_id === userAllDetails?.user_id
                        ? classes.activeUserMessageCard
                        : classes.messageCard
                    }`}>
                    {currentRoomdetails.room_details.type === 'group' && (
                      <>
                        <span
                          className={`${
                            msg.sender_id === userAllDetails?.user_id
                              ? classes.activesender
                              : classes.sender
                          }`}>
                          {msg.sender_details.user_name}
                        </span>
                        <br />
                      </>
                    )}
                    <span
                      className={`${
                        msg.sender_id === userAllDetails?.user_id
                          ? classes.activeMessageText
                          : classes.messageText
                      }`}>
                   {renderMessageContent(msg)}
                    </span>
                    <p
                      className={`${
                        msg.sender_id === userAllDetails?.user_id
                          ? classes.activeMessageTimestamp
                          : classes.timestamp
                      }`}>
                      <small>
                        <span className={classes.chatTimestampTicks}>
                          {new Date(msg.created_date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hourCycle: 'h23'
                          })}
                          { msg.sender_id === userAllDetails?.user_id && <ChatDoubleTicks
                            color={`${msg.read_by_user_ids?.length >= msg.current_users_count ? '#EE9C1F' : 'white'}`}
                            // '#A5B4FC' : '#475569'
                          />}
                        </span>
                      </small>
                    </p>
                  </div>
                  {msg.sender_id === userAllDetails?.user_id &&
                    currentRoomdetails.room_details.type === 'group' && (
                      <img src={ProfileImage} alt="profile_icon" />
                    )}
                </div>
              ))}
            </div>
          ))}
          <div ref={messagesEndRef} />
        
        </ScrollToBottom>
      </div>
      <ScrollToBottom>
      <SendMessageBox
        userId={userAllDetails?.user_id}
        roomId={roomChatId}
        isExpandChat={isExpandChat}
        userName={userAllDetails?.user_name}
        setIsTyping={setIsTyping}
        currentUsersCountInRoom = {currentUsersCountInRoom}
      />
      </ScrollToBottom>

      {/* <div className={classes.chatInputContainer}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message"
          className={classes.chatInput}
        />
        <button onClick={handleSend} className={classes.sendButton}>
          Send
        </button>
      </div> */}
     <ImagePreviewModal 
  isOpen={showPreview}
  images={imageUrls}
  currentIndex={currentIndex}
  onClose={() => setShowPreview(false)}
  onNext={() => setCurrentIndex(prev => prev + 1)}
  onPrev={() => setCurrentIndex(prev => prev - 1)}
/>

    </div>
  );
};

export default ChatRoomDetail;
