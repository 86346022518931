import { gql } from '@apollo/client';

export const CREATE_MESSAGE_SUBSCRIPTION = gql`
  subscription onCreateMessage($room_id: ID!) {
    onCreateMessage(room_id: $room_id) {
      message_id
      payload
      sender_id
      created_date
      attachments
      message_type
    }
  }
`;
