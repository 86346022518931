import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isExpandChat:boolean }>()(
  (_,{isExpandChat}) => ({
    roomContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        marginBottom: '8px',
        borderRadius: '8px',
        backgroundColor: '#f0f0f0',
        
        transition: 'background-color 0.2s ease',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#e8e8e8',
          
        },
      },
      roomInfo: {
        flex: 1,
        marginLeft: '10px',
      },
      roomName: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: 0,
      },
      lastUpdated: {
        fontSize: '12px',
        color: '#888',
        margin: '4px 0 0 0',
      },
      unreadCount: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: '#25D366',
        color: '#fff',
        fontSize: '12px',
        fontWeight: 'bold',
      },
      toggleContainer:{
      display:'flex',
      backgroundColor:"#F4F5F9",
      borderRadius:'10px',
      padding:"5px",
      width:"330px"

     },
     toggleButton: {
      flex: 1,
      border: 'none',
      padding: '10px 20px',
      borderRadius: '8px',
      backgroundColor: 'transparent',
      color: 'black',
      fontWeight:'500',
      fontSize: '12px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, color 0.3s ease',
     
    },
    activeButton:{
      flex: 1,
      border: 'none',
      padding: '10px 20px',
      borderRadius: '8px',
      backgroundColor: 'white',
      color: 'black',
      fontWeight:'500',
      fontSize: '12px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, color 0.3s ease',
    },
    
    listDetailsMainContainer:{
      height:'100vh',
      paddingleft:'0px',
      paddingTop:"0px",
      // width:'100%',
      display:'flex',
      flexDirection:"column",
      justifyContent:"space-between",
      alignItems:"flex-start",
      backgroundColor:"#ffffff",
      minWidth:isExpandChat? '400px':"100%", 
      '@media (min-width: 768px)': {
        display:'flex',
        flexDirection:"column",
        justifyContent:'flex-start',
        alignItems:"flex-start",
        paddingRight:'0px',
        paddingLeft:"0px",
        borderRight: "1px solid #e5e7eb",
        
      },

    },
    userListContainer:{
      paddingTop:"10px"
    },
    plusIcon: {
      fontSize: '23px',
      backgroundColor: '#F4F5F9',
      color: 'black', 
      width: '25px', 
      height: '25px', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      borderRadius: '8px',
      cursor:"pointer"
    },

    exitIcon: {
      fontSize: '20px',
      backgroundColor: '#F4F5F9',
      color: 'black', 
      width: '25px', 
      height: '25px', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      borderRadius: '8px',
      cursor:"pointer"
    },
    seachBarRecentChatsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch', // Ensures elements span the full width
      width: '100%',
      // Keep it fixed at the top within the container
      top: 0, // Stick to the top of the container
      zIndex: 10, // Ensure it's above other content
      backgroundColor: '#ffffff', // Optional: add a background color to match your container
      paddingBottom: '10px', // Optional: add spacing for better UI
      borderBottom: '1px solid #e0e0e0', // Optional: add a subtle border for separation
    },
    
    recentChatsContainer:{
      display:"flex",
      // width:'330px',
      justifyContent:"space-between",
      alignItems: 'center',
      paddingRight:"10px",
      paddingLeft:"10px",
      paddingTop:"10px",
      
    },
    recentChatsName:{
      paddingTop:'5px',
      fontSize:'18px',
      fontWeight:'500'
    },

    searchContainer:{
      marginTop:'10px',
      padding:'7px',
      paddingRight:"10px",
      marginLeft:"10px",
      borderRadius:'9px',
      // width:'330px',
      backgroundColor:'#F4F5F9',
      display:'flex',
      justifyContent:'flex-start',
      alignItems:'center',
      width:'365px'
    },
    fixedSizeList: {
      overflowY: "auto", 
      overflowX: "hidden", 
      
      "&::-webkit-scrollbar": {
        width: "3.5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
        borderRadius: "0px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "0px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
    fixedSizeList1: {
      overflowY: "auto", 
      overflowX: "hidden", 
      
      "&::-webkit-scrollbar": {
        width: "3.5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
        borderRadius: "0px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "0px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
    
   searchBar:{
    border:'none',
    backgroundColor:'#F4F5F9',
    outline:'none',
    paddingLeft:'7px',
    fontSize: '13px',
   },

   contactsMainContainer:{
    listStyleType:'none',
    width: '380px',
    height:isExpandChat? 'calc(87vh - 100px)': 'calc(92vh - 100px)',
    overflowY: 'auto',
    overflowX:"hidden",
    paddingLeft:"10px",
    paddingRight:"10px"
   },
   centeredMessage: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#888',
    width:'330px'
  },
  noRoomsContainer: {
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
   paddingTop:'110px',
   width:"100%",
   height: 'calc(92vh - 100px)',
  },
  noRoomsMessage: {
    textAlign: 'center',
    color: 'red',
    marginTop: '1rem',
    fontSize: '1.2rem',
  },
  noRoomDangerIcon:{
    color:'red',
    fontSize:'40px',
    paddingRight:'10px'
  },
  noRoomName:{
    fontSize:'23px',
    fontWeight:'500'
  }
  ,  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 50px)', // Full-height minus header (adjust as needed)
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#555',
    textAlign: 'center',
    width:"100%",
    backgroundColor: '#ffffff', // Optional background for contrast
  },

  loadingSpinner: {
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    border: '3px solid #ccc',
    borderTop: '3px solid #3377FF', // Primary color
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },

  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  footerContainer:{
    backgroundColor:'rgb(244, 245, 249)',
    height:'80px',
    paddingLeft:"0px",
    paddingRight:"0px",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    // borderTopRightRadius:'4px',
    width:'375px',

    
  },
  groupIconCss:{
    fontSize:"30px",
    padding:"10px",
    borderRadius:"10px"
    
  },
  groupIconsCss2:{
 fontSize:"30px",
    backgroundColor:"#E2E7F1",
    padding:"10px",
    borderRadius:"10px"
  },
  contactsCss:{
    fontSize:"30px",
    padding:"10px",
    borderRadius:"10px"
  },
  contactsCss2:{
    fontSize:"30px",
    backgroundColor:"#E2E7F1",
    padding:"10px",
    borderRadius:"10px"
  },

  
  
}));