import { gql } from '@apollo/client';

export const MESSAGE_STATUS_SUBSCRIPTION = gql`
  subscription OnUpdateMessageStatus {
    onUpdateMessageStatus{
      message_id
      is_reply
      message_type
      payload
      read_by_user_ids
      current_users_count
    }
  }
`;