import { makeStyles } from 'tss-react/mui';

export const useStyles=makeStyles()(()=>({


    groupNameIcon: {
        width: '38px',               
        height: '38px',              
        borderRadius: '50%',         
        display: 'flex',             
        alignItems: 'center',        
        justifyContent: 'center',    
        fontSize: '14px',            
        fontWeight: '600',          
        color: '#dfe6dc',               
        position: 'relative',        
        backgroundColor: '#FF5733',  
      },
      statusIndicator: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        border: '2px solid white',  // White border for a clean look
        backgroundColor: '#44D62C', // Green for online (change dynamically)
      },
      listItemMainContainer:{
        // width:'330px',
        marginTop:'20px',
        display:'flex',
        justifyContent:'start',
        alignItems:'center',
        cursor:"pointer",
        padding:"0 5px",
        width:'375px',
        paddingLeft:'10px',
        
        '&:hover':{
            backgroundColor:'#F4F5F9',
            borderRadius:'10px'
            
        },
    },
        contactDetailsContainer: {
            display: "flex",
            flexDirection: "column",
          },
          contactName: {
            paddingLeft:'15px',
            paddingBottom:'5px',
            fontSize:"14px",
            fontFamily:'Montserrat',
            fontWeight:'500',
            lineHeight:'18.29px',
            color:'#010929',
            maxWidth:'190px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap'
          },
          contactUserId: {
            fontSize: "0.9em",
            color: "#666",
            margin: "0",
          },
    


}));