import React from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { ChatImagePreviewType } from './ChatImagePreview.type';
import { useStyles } from './ChatImagePreview.style';

export const ImagePreviewModal: React.FC<ChatImagePreviewType> = ({ 
  isOpen, 
  images, 
  currentIndex = 0, 
  onClose, 
  onNext, 
  onPrev 
}) => {
  const { classes } = useStyles();
  
  if (!isOpen) return null;

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContent}>
        {/* Navigation */}
        {currentIndex > 0 && (
          <button 
            onClick={onPrev} 
            className={`${classes.navigationButton} ${classes.prevButton}`}
          >
            <ChevronLeft size={32} />
          </button>
        )}
        
        {currentIndex < images.length - 1 && (
          <button 
            onClick={onNext} 
            className={`${classes.navigationButton} ${classes.nextButton}`}
          >
            <ChevronRight size={32} />
          </button>
        )}

        {/* Close button */}
        <button 
          onClick={onClose} 
          className={classes.closeButton}
        >
          <X size={24} />
        </button>

        {/* Image */}
        
        <img 
          src={images[currentIndex]} 
          alt="Preview" 
          className={classes.previewImage}
        />

        {/* Image counter */}
        {images.length > 1 && (
          <div className={classes.imageCounter}>
            {currentIndex + 1} / {images.length}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagePreviewModal;