import { makeStyles } from 'tss-react/mui';

export const useChatStyles = makeStyles()(() => ({
  chatAttachmentcontainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    cursor:"pointer"
  },
  message: {
    marginBottom: '8px',
    wordBreak: 'break-word'
  },
  filesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px'
  },
  fileItem: {
    borderRadius: '8px',
    overflow: 'hidden'
  },
  fileIconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: '#F8FAFC',
    gap: '8px',
    maxWidth: '250px'
  },
  fileIcon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain'
  },
  imagePreview: {
    maxWidth: '200px',
    maxHeight: '200px',
    objectFit: 'contain',
    borderRadius: '8px',
  },
  fileName: {
    color: '#1E293B',
    fontSize: '12px',
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  downloadIcon: {
    width: "1.25rem",
    height: "1.25rem",
    color: "#64748b",
    transition: "color 0.2s ease",
  }
}));