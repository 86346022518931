import {takeEvery,all, fork} from 'redux-saga/effects';
import getArchitectPartnersListWatcher from './architectPartnersList';
import CustomerByIdWatcher from './CustomerById';
import designationListWatcher from './DesignationList';
import getCategoriesListWatcher from './getCategoriesList';
import getEducattionalListWatcher from './getEducationalList';
import getPartnerManagerListWatcher from './getpartnerManagersList';
import getSkillTypeListWatcher from './getSkillTypeList';
import getStatusListWatcher from './getStatusList';
// import getInteriorPartnersListWatcher from './interiorPArtnersList';
import loginWatcher from './Login';
import getAssociatedRolesWatcher from './RolesList';
import TurnkeyPartnersWatcher, { getTurnkeyPartners } from './turnkeyPartnersList';
import WeHouseUserByIdWatcher from './WeHouseUserById';
import getYearsOfExperienceListWatcher from './YearsOfExperienceList';
import getProjectTypeListWatcher from './getProjectTypeList';
import socketSaga from './socketSaga';
import { TurnkeyPartnersLoader } from '../Slices/turnkeyPartnersListSlice';
import chatServiceWatcher from './getChatService'
export default function* rootSaga(){
    // console.log('running saga watcher');
    // alert("inside the saga root");
    yield all([
        loginWatcher(),
        CustomerByIdWatcher(),
        WeHouseUserByIdWatcher(),
        designationListWatcher(),
        TurnkeyPartnersWatcher(),
        getYearsOfExperienceListWatcher(),
        getAssociatedRolesWatcher(),
        getArchitectPartnersListWatcher(),
        // getInteriorPartnersListWatcher(),
        getStatusListWatcher(),
        getSkillTypeListWatcher(),
        getCategoriesListWatcher(),
        getEducattionalListWatcher(),
        getPartnerManagerListWatcher(),
        getProjectTypeListWatcher(),
        chatServiceWatcher(),
        socketSaga(),
   
        
    ])
    // yield fork( loginWatcher)
    // yield fork( CustomerByIdWatcher)
    // yield fork( WeHouseUserByIdWatcher)
    // yield fork( designationListWatcher)
    // yield fork( TurnkeyPartnersWatcher)
    // // yield takeEvery("TKPLIST",getTurnkeyPartners())
    // yield fork( getYearsOfExperienceListWatcher)
    // yield fork( getAssociatedRolesWatcher)
    // yield fork( getArchitectPartnersListWatcher)
    // yield fork( getInteriorPartnersListWatcher)
    // yield fork( getStatusListWatcher)
    // yield fork( getSkillTypeListWatcher)
    // yield fork( getCategoriesListWatcher)
    // yield fork( getEducattionalListWatcher)
    // yield fork( getPartnerManagerListWatcher)
    // yield fork( getProjectTypeListWatcher)

}