import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  MenuChatAiMainContainer: {
    width: '454px',
    height: 'calc(100vh - 0px)',
    backgroundColor: '#ffffff',
    position: 'fixed',
    right: '40px',
    top:"0px",
    zIndex: '99999',
    display: 'flex',
    '@media (max-width: 768px)': {
      width: "400px"
    }
  },
  AiChatContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    width: '394px',
    position:'absolute',
    right:'0',
  },
  CloseAiChatButton: {
    height: '20px',
    width: '20px',
    backgroundColor: '#F2F2F2',
    borderRadius: '4px',
    border: 'none',
    margin: '10px',
    alignSelf: 'flex-end'
  },
  AiTextBoxContainer: {
    height: '118px',
    borderTop: '1px solid #EAEAEA',
  },
  AiInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%'
  },
  AiTextInput: {
    border: 'none',
    outline: 'none',
    margin: '10px'
  },
  AiTextBottomContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  AddImagesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%'
  },
  imageContainer: {
    display: 'flex',
    color: '#3377FF',
    fontSize: '13px',
    marginLeft: '8px'
  },
  imageText: {
    marginLeft: '4px'
  },
  sendButton: {
    height: '30px',
    width: '68px',
    color: '#ffffff',
    borderRadius: '8px',
    border: '1px',
    backgroundColor: '#3377FF',
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sendButtonText: {
    marginRight: '5px'
  },
  RightSidebar: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '300px', // Adjust width as needed
    height: '100%',
    background: 'white', // Add your background color
    boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
    zIndex: 100, // Ensure it’s above other elements
  },
  ToggleSidebarButton: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    background: '#007bff',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  rightSideNavBarContainer: {
    width: '60px',
    backgroundColor: '#fff',
    padding: '5px 6px',
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderLeft: '1px solid #EAEAEA',
    borderRight: '1px solid #EAEAEA',
    '@media (max-width: 576px)': {
      display:"none"
    }
  },
  rightSideButtonToggle: {
    border: '1px solid #e2e7f1',
    cursor: 'pointer',
    backgroundColor: "#f8f9fd",
    padding: "10px",
    borderRadius: "5px"
  },
  tooltip: {
    height:"80%",
    width:"300px",
    // backgroundColor:"",
    position: 'absolute',
    backgroundColor: '#e8e4e3',
    border: '1px solid #ccc',
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    // top: '40px', /* Adjust based on your layout */
    left: '48%', /* Center horizontally */
    transform: 'translateX(-50%)',
  },
  welcomeContainer: {
    display: 'flex',
    justifyContent: 'center',   
    alignItems: 'center',      
    position: 'absolute',
    top: '50%',                
    left: '50%',                
    transform: 'translate(-50%, -50%)', 
    fontFamily: 'Montserrat',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '20px',
    whiteSpace: 'pre',
    overflow: 'hidden',
    color: 'black',
    width: '100%',
  }
  
  
}));
