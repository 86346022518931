import { takeEvery, put, call } from "redux-saga/effects";
import { DropDownServices } from "../../Service/DropDownServices"; // Update this import as per your project structure
import {
  setchatRoomDetails,
  setChatRoomId,
  setUserDetailsInRedux,
} from "../Slices/ChatServiceReduxSlice";

// Action types for your slice
export const GET_CHAT_ROOM_DETAILS = "GET_CHAT_ROOM_DETAILS";
export const GET_CHAT_ROOM_ID = "GET_CHAT_ROOM_ID";
export const GET_USER_DETAILS = "GET_USER_DETAILS";

// Service to fetch chat room details
export const getChatRoomDetailsService = async () => {
  try {
    const response = await fetch('/api/chatRoomDetails');  // Replace with actual API endpoint
    const data = await response.json();
    if (response.ok) {
      return {
        status: 'success',
        data: data,
      };
    }
    return {
      status: 'failure',
      message: data.message || 'Failed to fetch chat room details',
    };
  } catch (error) {
    return {
      status: 'failure',
      message: error || 'Error fetching chat room details',
    };
  }
};

// Service to fetch chat room ID
export const getChatRoomIdService = async () => {
  try {
    const response = await fetch('/api/chatRoomId');  // Replace with actual API endpoint
    const data = await response.json();
    if (response.ok) {
      return {
        status: 'success',
        data: data,
      };
    }
    return {
      status: 'failure',
      message: data.message || 'Failed to fetch chat room ID',
    };
  } catch (error) {
    return {
      status: 'failure',
      message: error || 'Error fetching chat room ID',
    };
  }
};

// Service to fetch user details
export const getUserDetailsService = async () => {
  try {
    const response = await fetch('/api/userDetails');  // Replace with actual API endpoint
    const data = await response.json();
    if (response.ok) {
      return {
        status: 'success',
        data: data,
      };
    }
    return {
      status: 'failure',
      message: data.message || 'Failed to fetch user details',
    };
  } catch (error) {
    return {
      status: 'failure',
      message: error || 'Error fetching user details',
    };
  }
};

// Worker function to handle fetching chat room details
function* fetchChatRoomDetails() {
  const response = yield call(getChatRoomDetailsService);
  if (response.status === 'success') {
    yield put(setchatRoomDetails({ chatRoomDetails: response.data }));
  } else {
    console.error(response.message);
  }
}

// Worker function to handle fetching chat room ID
function* fetchChatRoomId() {
  const response = yield call(getChatRoomIdService);
  if (response.status === 'success') {
    yield put(setChatRoomId({ chatRoomId: response.data }));
  } else {
    console.error(response.message);
  }
}

// Worker function to handle fetching user details
function* fetchUserDetails() {
  const response = yield call(getUserDetailsService);
  if (response.status === 'success') {
    yield put(setUserDetailsInRedux({ userDetails: response.data }));
  } else {
    console.error(response.message);
  }
}

// Watcher function to monitor dispatched actions
function* watchChatServiceActions() {
  yield takeEvery(GET_CHAT_ROOM_DETAILS, fetchChatRoomDetails);
  yield takeEvery(GET_CHAT_ROOM_ID, fetchChatRoomId);
  yield takeEvery(GET_USER_DETAILS, fetchUserDetails);
}

export default watchChatServiceActions;
