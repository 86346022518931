import { gql } from '@apollo/client';

export const UPDATE_MESSAGE_STATUS = gql`
  mutation UpdateMessageStatus(
    $message_id: String!
    $room_id: String!
    $user_id: String!
  ) {
    updateMessageStatus(
      message_id: $message_id
      room_id: $room_id
      user_id: $user_id
    ) {
      message_id
      is_reply
      message_type
      payload
      read_by_user_ids
      current_users_count
    }
  }
`;
