import { gql } from '@apollo/client';

export const SEND_TYPING_NOTIFICATION = gql`
  mutation SendTypingNotification($roomId: String!, $userId: String!, $isTyping: Boolean!) {
    sendTypingNotification(roomId: $roomId, userId: $userId, isTyping: $isTyping) {
      userId
      roomId
      isTyping
    }
  }
`;