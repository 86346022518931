import { takeEvery, put } from "redux-saga/effects";
import { setSocketData, setSocketError } from "../Slices/socketSlice";

// Action type for socket event
export const SOCKET_EVENT = "SOCKET_EVENT";

// Socket event listener
export function* handleSocketEvent(action) {
  try {
    const data = action.payload;
    yield put(setSocketData(data));
  } catch (error) {
    yield put(setSocketError(error.message));
  }
}

export default function* socketSaga() {
  yield takeEvery(SOCKET_EVENT, handleSocketEvent);
}