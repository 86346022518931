import { gql } from '@apollo/client';

export const GET_ACCOUNT_USERS_BY_SEARCH = gql`
  query GetAccountUsersBySearch($account_id: ID!, $userSearchInput: String!) {
    GetAccountUsersBySearch(account_id: $account_id, userSearchInput: $userSearchInput) {
        account_id
        user_id
        user_details {
            user_name
            associatedAccounts {
                account_details {
                    account_name
                }
            }
        }
    }
  }
`;
