import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useStyles } from './RightChatEnginePanel.style.ts';
// import {chatRoomDetails} from '../../Features/Slices/ChatServiceReduxSlice.js';
// import RightSideMenuBar from '../RightSideMenuBar/RightSideMenuBar';
// import Right from './Right';
// import { CrossIcon, AddIcon, AddImageIcon, SendIcon } from './Icons/icons.tsx'
import { MenuChatAiTypes, RoomDetails } from './RightChatEnginePanel.types.ts';
import ApolloWrapper from './ApolloWrapper.tsx';
// import { gql, useQuery } from '@apollo/client';
// import RightSideMenuBar from '../RightSideMenuBar/RightSideMenuBar';
import ChatRoomDetail from './ChatService/Service/ChatRoom/ChatRoomDetail.tsx';
import ChatFullScreen from './ChatService/ChatFullScreen/ChatFullScreen.tsx';

import RoomList from './ChatService/Service/RoomList/RoomList.tsx';
// import { gql, useSubscription,  useMutation, useQuery } from "@apollo/client";
// import { v4 as uuidv4 } from "uuid";
// import Chat from '../../ChatService/Service/Chat'f
// import { AiIcon, ChatIcon, Support } from '../../../Icons/icons';
import RoomInfoScreen from './ChatService/Service/RoomInfoScreen/RoomInfoScreen.tsx';
import { chatDetailClicked1,chatButtonClicked,chatOpenClicked } from '../../Features/Slices/ChatServiceReduxSlice.js';
import { useSelector, 
  useDispatch 
} from "react-redux";


const customStyles = {
  height: '100%'
};

// Variants for the container animation
// const containerVariants = {
//   hidden: {
//     opacity: 0,
//     scale: 0.5
//   },
//   visible: {
//     opacity: 1,
//     scale: 1,
//     transition: {
//       duration: 0.5,
//       ease: 'easeInOut'
//     }
//   }
// };

// // Variants for child elements animation;
// const childVariants = {
//   hidden: {
//     y: 20,
//     opacity: 0
//   },
//   visible: {
//     y: 0,
//     opacity: 1,
//     transition: {
//       type: 'spring',
//       stiffness: 300,
//       damping: 20,
//       duration: 0.5
//     }
//   }
// };

const sidebarVariants = {
  hidden: {
    x: '100%' // Start outside the viewport on the right
  },
  visible: {
    x: 0, // Slide into the viewport
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  }
};
const RightChatEnginePanel: React.FC<MenuChatAiTypes> = ({
  isExpandChat,
  handleExpandChat,
  isChatBtnClicked,
  setIsChatBtnClicked,
  setIsExpandChat,
  isChatDetailClicked,
  setIsChatDetailClicked,

}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch()

  // const [inputValue, changeInputValue] = useState<string>('');
  // const [roomId, setRoomId] = useState('');
  // const [userId, setUserId] = useState('');
  const [isSidebarVisible, 
    _] = useState(false);
  // const [username, setUserName] = useState('');
  // const [isChatButtonClicked, setIsChatButtonClicked] = useState(false);
  // const [isChatDetailClicked, setIsChatDetailClicked] = useState(false);
  const [roomDetails, setRoomDetails] = useState<RoomDetails | null>(null);
  const [userDetails, setUserDetails] = useState('');
 
  // const isChatBtnClicked = useSelector((state: any) => state.ChatServiceReduxSlice.isChatBtnClicked);
  // const theme = useSelector((state: any) => state.theme.currentTheme);

  // console.log(isChatButtonClicked,"isChatButtonClicked")
  // console.log(isChatDetailClicked,"isChatDetailClicked")

  
  const [text, setText] = useState('');
  // const handleChatButtonClickRoomList = () => {
  //   // setIsChatButtonClicked((prev) => !prev);
  //   dispatch(chatDetailClicked1())
  //   dispatch(chatButtonClicked())
  //   // setIsChatDetailClicked(false);
   
  // };
  console.log(setText)
  // console.log(isChatButtonClicked)
// console.log(setSidebarVisible)
  const chatDetailClicked = (room: any, userData: any) => {
    setIsChatDetailClicked(true);
//  dispatch(chatDetailClicked1())
    // setIsChatButtonClicked(false);
    dispatch(chatButtonClicked())

    setRoomDetails(room);
    setUserDetails(userData);
  };
  // const { data : getAllUserMessages ,error:userError}=useQuery(getAllUserMessagesById,{
  //   variables:{roomId}, skip: !roomId})

  // if (userError) {
  //  console.log(userError.message)
  // }

  // if (getAllUserMessages) {
  //  console.log(getAllUserMessages);
  // }
 
// console.log("userDetails in rc",userDetails);
const isRoomDescriptionClicked = useSelector(
  (state: any) => state.ChatServiceReduxSlice.isRoomDescriptionClicked
);

// const isChatButtonClicked=useSelector(
//   (state:any)=>state.ChatServiceReduxSlice.isChatButtonClicked
// )
// const isChatDetailClicked=useSelector(
//   (state:any)=>state.ChatServiceReduxSlice.isChatDetailClicked
// )


useEffect(()=>{
  console.log("isExpandChat in rt",isExpandChat)
},[isExpandChat])

console.log("isRoomDescriptionClicked",isRoomDescriptionClicked)

  return (
    <ApolloWrapper>
    
        

        <div className={classes.AiChatContainer} > 
          {/* <button onClick={() => handleCloseMenuChatAi()} className={classes.CloseAiChatButton}>
            <CrossIcon />
          </button> */}
          <motion.div>
            {/* <motion.div>           
            <button onClick={() => handleExpandChat()}>expand</button>
          </motion.div> */}
            {isChatDetailClicked && !isRoomDescriptionClicked ? (
              <ChatRoomDetail
                roomDetails={roomDetails}
                userDetails={userDetails}
                handleExpandChat={handleExpandChat}
                isExpandChat={isExpandChat}
          
                isChatBtnClicked={isChatBtnClicked}
                setIsChatBtnClicked={setIsChatBtnClicked}
                isChatDetailClicked={isChatDetailClicked}
                  setIsChatDetailClicked={setIsChatDetailClicked}
                setRoomDetails={setRoomDetails}
              

              />

              
            ) :isRoomDescriptionClicked? <RoomInfoScreen  roomId={roomDetails?.room_id} userDetails={userDetails} isExpandChat={isExpandChat}/> : null}
          </motion.div>
          <motion.div>
            <div>
              {!isChatDetailClicked ? (
                <RoomList chatDetailClicked={chatDetailClicked}
                isChatBtnClicked={isChatBtnClicked}
                setIsChatBtnClicked={setIsChatBtnClicked}
                isChatDetailClicked={isChatDetailClicked}
                isExpandChat={isExpandChat}
                setIsExpandChat={setIsExpandChat}
                setIsChatDetailClicked={setIsChatDetailClicked}
              
                />
              ) : !isChatDetailClicked ? (
                <div className={classes.welcomeContainer}>
                  <p>{text}</p>
                </div>
              ) : null}

       
            </div>
          </motion.div>
        </div>
   
    </ApolloWrapper>
  );
};

export default RightChatEnginePanel;
