
import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query getUserById($user_id: ID!) {
    getUserById(user_id: $user_id) {
      email_id
      user_id
      user_name
    }
  }
`;
