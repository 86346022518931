import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles<{ isExpandChat:boolean }>()(
  (_,{isExpandChat}) => ({
   sendMessageBoxContainer:{
    backgroundColor:"#FFFFFF",
    maxHeight:"300px",
    borderTopRightRadius:"5px",
    borderTopLeftRadius:"5px",
   //  width:"100%",
    // zIndex:999,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px -0.1px 0.3px',
    padding:"10px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    marginbottom:'0px',
    marginLeft:isExpandChat ? "15%":"0px",
    marginRight:isExpandChat ? "15%":"0px",
    // flexDirection: 'column-reverse'
   }
   ,
   sendMessageInput:{
    color:"#777777",
    borderWidth:"0px",
    outline:"none",
    maxHeight:"190px",
    overflow:"auto",
    resize:"none",
    width:"100%",
    minHeight: "20px",
    marginBottom: '5px'
   },
   iconContainer:{
    border: "2px solid #EDEDED",
    borderRadius:"123px",
    width:"30px",
    height:"30px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginRight:"10px",
    cursor:"pointer"
   },
   sendMessageButton:{
    // backgroundColor:"#3377FF",
    backgroundColor:"#F8A504",
    width:"80px",
    height:"30px",
    color:"#ffffff",
    borderRadius:"20px",
    display:"flex",
    justifyContent:"space-evenly",
    alignItems:"center"
   },
   iconFlexContainer:{
    display:"flex",
    justifyContent:"space-between"
   },
   subIconFlexContainer:{
    display:"flex",
    // justifyContent:"space-between
   },
   sendText:{
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing:" -0.006em",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color:"rgb(255, 255, 255)",
    margin:"0px"

   },
   sendIcon:{
    width: "16px",
    height: "40px",
    padding: "0px 1.51px 0px 2.25px",
    gap:" 0px",
    opacity: "0px",

   },
   emojiPickerContainer: {
    position: 'absolute',
    bottom: '60px',
    right: isExpandChat?'68px':'20px', 
    zIndex: 1000,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
    borderRadius: '8px',
    
  },
  disabledButton: {
    cursor: 'not-allowed',
    opacity: 0.5, 
    pointerEvents: 'none', 
    backgroundColor: '#acc8f2', 
    color: '#ffffff', 
    transition: 'opacity 0.3s ease',
  },
  attachmentPreviewContainer: {
    display: "flex",
    justifyContent: "flexStart",
    alignItems: "center",
    marginBottom: "10px",
    position:"absolute",
    // zIndex:"99999"
  },
  attachmentPreview:{
    height: "90px",
    width: "60px",
    // objectFit: "contain",
    borderRadius: "4px",
    marginRight: "10px",
  },
  
  fileUploadModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "#FFFFFF",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    maxHeight: "80%",
    overflowY: "auto",
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },
  filePreviewContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: "10px",
    margin: "10px 0",
    zIndex:"99999",
    position:"absolute"
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: "15px",
  },
  container: {
    width: '100%',
  },
  messageContainer: {
    // position: 'relative',
    border: '1px solid #e5e7eb',
    borderRadius: '0.5rem',
    padding: '0.5rem',
    marginBottom: '10px',
    flexDirection: 'column-reverse', 
    // minHeight: '100px',
  },
  filePreviewsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    marginBottom: '0.5rem',
    maxHeight: '153px', 
    overflowY: 'auto', 
  },
  filePreviewItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    backgroundColor: '#f3f4f6',
    borderRadius: '0.5rem',
    padding: '0.5rem',
    paddingRight: '0.75rem',
  },
  filePreviewContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  filePreviewThumb: {
    width: '2rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imagePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '0.25rem',
  },
  fileTypeContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    borderRadius: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem',
    
  },
  fileName: {
    fontSize: '0.875rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '150px',
  },
  removeButton: {
    padding: '0.25rem',
    borderRadius: '9999px',
    '&:hover': {
      backgroundColor: '#e5e7eb',
    },
  },
  removeIcon: {
    width: '1rem',
    height: '1rem',
  },
  messageInput: {
    width: '100%',
    resize: 'none',
    outline: 'none',
    border: 'none',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  fileInputContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
  fileInput: {
    display: 'none',
  },
  attachButton: {
    cursor: 'pointer',
  },
  attachIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  }));