import React from 'react';
import { useChatStyles } from './ChatAttachmentFiles.style';
import jsonImage from '../../../assets/chatJsonImage.png';
import chatDocImage from '../../../assets/chatDocImage.png';
import chatPdfImage from '../../../assets/chatPdfImage.png';
import chatXlsImage from '../../../assets/chatXlsImage.png';
import { Download } from 'lucide-react';

interface FileData {
  extension: string;
  mime_type: string;
  name: string;
  url: string;
}

interface ChatAttachmentFilesProps {
  file: string[];
  message?: string;
}

const fileTypeIcons: Record<string, string> = {
  json: jsonImage,
  doc: chatDocImage,
  docx: chatDocImage,
  pdf: chatPdfImage,
  xls: chatXlsImage,
  xlsx: chatXlsImage
};

const isImageType = (mimeType: string) => {
  return mimeType.startsWith('image/');
};

const ChatAttachmentFiles: React.FC<ChatAttachmentFilesProps> = ({ file, message }) => {
  const { classes } = useChatStyles();
  const files: FileData[] = file?.map((fileStr: string) => JSON.parse(fileStr));

  const getFileIcon = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase() || '';
    return fileTypeIcons[extension] || chatDocImage;
  };

  return (
    <div className={classes.chatAttachmentcontainer}>
      {message && <div className={classes.message}>{message}</div>}
      <div className={classes.filesContainer}>
        {files?.map((fileData, index) => (
          <div key={index} className={classes.fileItem}>
            {isImageType(fileData.mime_type) ? (
              <img 
                src={fileData.url}
                alt={fileData.name}
                className={classes.imagePreview}
                // style={{ filter: "blur(5px)" }}
              />
            ) : (
              <div className={classes.fileIconContainer}>
                <img 
                  src={getFileIcon(fileData.name)}
                  alt={fileData.extension}
                  className={classes.fileIcon}
                />
                <a 
                  href={fileData.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.fileName}
                >
                  {fileData.name}
                <Download className={classes.downloadIcon} />
                </a>

              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatAttachmentFiles;