import {gql} from '@apollo/client'

export const GET_ACCOUNT_USERS = gql`
query GetAccountUsers($account_id: ID!) {
  GetAccountUsers(account_id: $account_id) {
    account_id
    user_id
    user_details {
      user_name
      associatedAccounts {
        account_details {
          account_name
        }
      }
    }
  }
}
`