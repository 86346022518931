import { gql } from '@apollo/client';

export const ON_CREATE_MESSAGE_USER_ROOM_FILTER = gql`
  subscription MySubscription($user_id: String!) {
    onCreateMessageUserRoomFilter(user_id: $user_id) {
      payload
      room_id
    }
  }
`;
