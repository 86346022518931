

import { gql } from '@apollo/client';

// export const GET_USER_ROOMS = gql`
//   query MyQuery($user_id: ID!) {
//     getUserRooms(input: { user_id: $user_id }) {
//       user_name
//       user_id
//       email_id
//       userRooms {
//         last_updated
//         room_id
//         unread_message_count
//         room_details {
//           associatedUsers {
//             room_role
//             user_id
//             user_details {
//               email_id
//               userRooms {
//                 last_updated
//                 room_details {
//                   associatedUsers {
//                     room_role
//                     user_id
//                   }
//                   id
//                   name
//                   type
//                   lastMessage {
//                     room_id
//                     room_user_ids
//                     sender_id
//                     reply_to_message_id
//                     payload
//                     message_type
//                     message_id
//                     is_reply
//                     is_forwarded
//                     reply_to_message {
//                       sender_id
//                       room_user_ids
//                       room_id
//                       reply_to_message_id
//                       payload
//                       message_type
//                       message_id
//                     }
//                   }
//                 }
//               }
//               user_id
//               user_name
//             }
//           }
//           id
//           name
//           type
//         }
//       }
//     }
//   }
// `;




export const GET_USER_ROOMS = gql`
 query MyQuery($user_id: ID!) {
  getUserRooms(input: { user_id: $user_id }) {
     userRooms {
      unread_message_count
      room_details {
        lastMessage {
          created_date
          payload
          message_type
        }
        name
        type
      }
      room_id
    }
    user_id
    user_name
  }
}
`