import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

const ChatServiceReduxSlice = createSlice({
    name: "chatRoomDetails",
    initialState: {
        chatRoomDetails: [],
        chatRoomId:"",
        userDetailsInRedux:[],
        isRoomDescriptionClicked: false,
        isChatBtnClicked:false,
        isChatDetailClicked:false,
        isChatopen:false,
        unreadMessagesCount:0


    },
    reducers: {
        setchatRoomDetails: (state, action) => {
            state.chatRoomDetails = action.payload.chatRoomDetails
        },
        setChatRoomId: (state, action)=>{
            state.chatRoomId=action.payload.chatRoomId
        },
        setUserDetailsInRedux:(state, action)=>{
            state.userDetailsInRedux=action.payload.userDetails
        },
        toggleRoomDescriptionClicked: (state) => {
            state.isRoomDescriptionClicked = !state.isRoomDescriptionClicked;
        }, 
        chatButtonClicked:(state)=>{
            // console.log("action payload in chat button",state.isChatBtnClicked)
            state.isChatBtnClicked=!state.isChatBtnClicked
            console.log("action payload in chat button",state.isChatBtnClicked)

        },
        chatDetailClicked1:(state)=>{
            state.isChatDetailClicked=!state.isChatDetailClicked
        },
        chatOpenClicked:(state)=>{
            state.isChatopen=!state.isChatopen
        },
        
   

    }
});
export const { setchatRoomDetails,setChatRoomId,setUserDetailsInRedux,toggleRoomDescriptionClicked,chatButtonClicked,chatDetailClicked1,chatOpenClicked,setChatUnreadMessages} = ChatServiceReduxSlice.actions;
export default ChatServiceReduxSlice.reducer 