import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import Forgot from "./Components/Forgot";
import Signin from "./Components/Signin.js";
import AdminLanding from "./Components/Roles/AdminLanding";
import Landing from "./Components/Landing";
import SignUp from './Components/SignUp';
import Verify from "./Components/Verify";
import ResetPassword from "./Components/ResetPassword";
// import RightChatEnginePanel  from './Service/RightChatEnginePanel/RightChatEnginePanel.tsx'
// import PeLanding from './Components/Roles/PeLanding';
// import PmLanding from "./Components/Roles/PmLanding";
// import TkpLanding from "./Components/Roles/TkpLanding";
// import SeLanding from "./Components/Roles/SeLanding";
import './Styles/scss/App.scss';
import './Styles/scss/SmallDevices.scss';
import Toaster from "./Pages/Admin/Toaster";
import "simplebar/src/simplebar.css"
import { useEffect } from "react";
import './App.scss'


function App() {
  let timer;

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  /* On Initial rendering adding event listener,based on these events tracking user activities */
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        // console.log("item--", item);
        resetTimer();
        sessionOutTimer();
      });
    });
  }, []);

  /* Reset the timer */
  const resetTimer = () => {
    // console.log("reset-timemer---", timer);
    if (timer) clearTimeout(timer);
  };

  /* Handler that autologout users after a specific time of inactivity */
  const sessionOutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();                     // clears if any pending timer exists.
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    },12 * 60 * 60 * 1000); // 10000ms = 10secs.    60 * 60 * 1000= 3600000 milisec/ 1hr
  };

  /* Logout users */
  const logoutAction = () => {
    // localStorage.clear();
    console.log("logoutt----");
    window.location.pathname = "/signin";
  };

  return (
    <div className="App">
      <Toaster />
      <Router>
        <Route exact path="/" render={() => {
          return <Redirect to="/signin" />
        }
        } />
        <Route path='/signUp' component={SignUp} />
        <Route path='/signin' component={Signin} />
        <Route path='/forgot' component={Forgot} />
        <Route path="/verify" component={Verify} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/landing" component={Landing} />
        {/* <Route path="/chatService" component={RightChatEnginePanel} /> */}
        {/* <Route path='/adminlanding' component={AdminLanding} /> */}
        {/* <Route path='/pelanding' component={PeLanding} />
        <Route path='/pmlanding' component={PmLanding} />
        <Route path="/tkplanding" component={TkpLanding} />
        <Route path='/selanding' component={SeLanding} /> */}
      </Router>

    </div>

  );
}

export default App;
