import React, { useState } from 'react';
import { useStyles } from './ChatFullScreen.style';
import type {  ChatFullScreen as ChatFullScreenProps , RoomDetails } from './ChatFullScreen.types';
import RoomList from '../Service/RoomList/RoomList';
import ChatRoomDetail from '../Service/ChatRoom/ChatRoomDetail';
import ApolloWrapper from '../../ApolloWrapper';
import { useSelector } from 'react-redux';
import RoomInfoScreen from '../Service/RoomInfoScreen/RoomInfoScreen';

const ChatFullScreen: React.FC<ChatFullScreenProps> = ({ handleExpandChat,isExpandChat,isChatBtnClicked,setIsChatBtnClicked,isChatDetailClicked,setIsChatDetailClicked,setIsExpandChat

}) => {
  const [roomDetails, setRoomDetails] = useState<RoomDetails | null>(null);
  const [userDetails, setUserDetails] = useState('');
  const isRoomDescriptionClicked=useSelector((state:any)=>state.ChatServiceReduxSlice.isRoomDescriptionClicked)
  const { classes } = useStyles({isRoomDescriptionClicked});
  console.log("chat detail in full screen",isChatDetailClicked)
 
  const chatDetailClickedInFull = (room: any, userData: any) => {
    console.log('it logged');
    setRoomDetails(room);
    setUserDetails(userData);
    console.log('roomDataINFULL', room);
    console.log('userDataINFULL', userData);
  };
  

  return (
    <ApolloWrapper>
      <div className={classes.expandMainFullScreenContainer}
   
      >
        
        <RoomList chatDetailClicked={chatDetailClickedInFull} isExpandChat={isExpandChat}
        isChatBtnClicked={isChatBtnClicked}
        setIsChatBtnClicked={setIsChatBtnClicked}
        isChatDetailClicked={isChatDetailClicked}
        setIsChatDetailClicked={setIsChatDetailClicked}
        setIsExpandChat={setIsExpandChat}
        handleExpandChat={handleExpandChat}
  
        />
        {roomDetails  ? (
         
              
          <ChatRoomDetail
            userDetails={userDetails}
            handleExpandChat={handleExpandChat}
            roomDetails={roomDetails}
            isExpandChat={isExpandChat}
            isChatBtnClicked={isChatBtnClicked}
              setIsChatBtnClicked={setIsChatBtnClicked}
              isChatDetailClicked={isChatDetailClicked}
              setIsChatDetailClicked={setIsChatDetailClicked}
              setRoomDetails={setRoomDetails}
     
           
            
          />
          
          
        ) :  (
          <div className={classes.emptyStateContainer}>
            <p style={{fontSize:"inherit"}}>Select User To Start Messaging</p>
          </div>
        )}
        {isRoomDescriptionClicked && <RoomInfoScreen roomId={roomDetails?.room_id} userDetails={userDetails} isExpandChat={isExpandChat}/>}
      </div>
    </ApolloWrapper>
  );
};

export default ChatFullScreen;
