import React from 'react';
import { CircularProgress } from '@mui/material';

const circularProgressStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};
interface LoaderProps{
  color? : "secondary"|"success"|"inherit";
  size? :number

}

const CircularProgressComponent: React.FC<LoaderProps> = ({color,size}) => {
  return (
    <div style={circularProgressStyle}> 
      <CircularProgress size={size} color={color}/>
    </div>
  );
};

export default CircularProgressComponent;
