import React from 'react';
import ReactDOM from 'react-dom';
import 'rc-pagination/assets/index.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import App from './App';
import { Provider } from 'react-redux';
import store from './Store/Store';

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';


const client = new ApolloClient({
  uri: 'https://m5gxckh7mfdllogqompq5i4oze.appsync-api.ap-south-1.amazonaws.com/graphql', 
  cache: new InMemoryCache(),
  headers: {
    'x-api-key': 'da2-dkegsbhe5nayplv5nsln5e34re', // Add authentication if required
  },
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

