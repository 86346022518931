import { gql } from '@apollo/client';

export const UPDATE_ROOM_ROLE_FOR_USER = gql`
  mutation UpdateRoomRoleForUser($input: updateRoomRoleForUserInput!) {
    updateRoomRoleForUser(input: $input) {
        updated_by
        room_id
        updated_for
        updated_room_role
    }
  }
`;
