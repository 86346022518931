import { gql } from '@apollo/client';

export const CREATE_MESSAGE = gql`
  mutation MyMutation($input: createMessageInput!) {
    createMessage(input: $input) {
        created_date
        message_id
        payload
        sender_id
        room_id
        room_user_ids
        attachments
        message_type
        read_by_user_ids
    }
  }
`;