import { gql } from '@apollo/client';

export const CREATE_GROUP_WITH_USERS = gql`
  mutation CreateGroupWithUsers($input: createGroupWithUsersInput!) {
    createGroupWithUsers(input: $input) {
      room_details {
        name
      }
    }
  }
`;
