import { Radio, Result, Tooltip, Upload } from "antd"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStatusListType } from "../../../../../Features/Saga/getStatusList"
import { blockInvalidChar } from "../../../../../Utilities/helpers"
import "./CustomerDetails.css"
import { BsUpload } from "react-icons/bs"
import {
    addProjectService,
    getProjectByCustomerId,
    updateProjectService
     
} from "../../../../../Service/SeCustomersServices"
import { DatePicker, Switch } from 'antd'

import { toast } from "react-toastify"
import { BiLeftArrowAlt } from "react-icons/bi"
import { GETPROJECTTYPELIST } from "../../../../../Features/Saga/getProjectTypeList";

import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineSaveAs } from "react-icons/hi";
import { MdPreview } from "react-icons/md";
import {getUserCities} from '../../../../../Service/RawLeadsService' 
import moment from "moment"



function ProjectDetails({
    toggleHandler,
    handleTabs,
    type,
    setCustomerIdFromApi,
    customerIdFromApi,
    projectDetailsForPayments,
    newLead
}) {
    console.log("project screen details", type, newLead)

  
    const [date, setDate] = useState(null);
    const [projectDetails, setProjectDetails] = useState({
        projectId:"",
        projectName: "",
        contractType: "",
        projectLocation: "",
        pinCode: "",
        city: "",
        projectType: "",
        noOfFloors: null,
        plotArea: "",
        slabArea: "",
        penthouse: "No",
        penthouseSlabArea: "",
        buildArea: "",
        totalCost: "",
        permissions: ["No"],
        status: "Yet To Allocate",
        projectLatitude: "",
        projectLongitude: "",
        startDate:null
    })
    const [errorMsg, setErrorMsg] = useState({
        projectName: "",
        contractType: "",
        projectLocation: "",
        pinCode: "",
        city: "",
        projectType: "",
        noOfFloors: null,
        plotArea: "",
        slabArea: "",
        penthouse: "No",
        penthouseSlabArea: "",
        buildArea: "",
        totalCost: "",
        permissions: ["No"],
        status: "Yet To Allocate",
        projectLatitude: "",
        projectLongitude: ""
    })
    const [floorOptions, setFloorOptions] = useState([])
    const [showUpload,setShowUpload] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState(null); 
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [seCity,setSeCity]=useState('');
    const [dateEnabled, setDateEnabled] = useState(false);
    const [switchEnabled, setSwitchEnabled] = useState(true);
    const dispatch = useDispatch()
    const statusList = useSelector((state) => state.getStatusListSlice.status)
    const projectTypeList = useSelector((state) => state.dropDownListSlice.projectTypeList)
    console.log("statusList::", statusList, projectTypeList)
    const disabledDate = (current) => {
        return current && current.isAfter(moment().endOf("day"));
    }
    const ProjectHandlerChange = (e) => {
        const { name, value } = e.target
        console.log("name", e.target)
        setErrorMsg((prev) => ({ ...prev, [name]: "" }))
        if (name === "government") {
            let tempData = projectDetails?.permissions
            if (name === "government") {
                tempData[0] = value
                console.log("tempData", tempData)
                setProjectDetails((prevState) => ({ ...prevState, permissions: [...tempData] }))
                setShowUpload(value === "yes")
            }
           
        } else {
            if (name === "pinCode") {
                setProjectDetails((prevState) => ({ ...prevState, [name]: value.slice(0, 6) }))
            } else {
                setProjectDetails((prevState) => ({
                    ...prevState,
                    [name]: value
                }))
            }
        }
    }
    useEffect(() => {
        if (projectDetails.permissions[0] === "yes") {
            setShowUpload(true);
        } else {
            setShowUpload(false);
        }
    }, [projectDetails]);
const handleFileChange = (e)=>{
    const files = Array.from(e.target.files)
    const validFiles = files.filter(file=> 
    file.type === 'application/pdf')
    if(validFiles.length>0){
    setUploadedFiles(prevFiles=>[...prevFiles,...validFiles])}
}
console.log(uploadedFiles,"uploadedFiles")
const getFileIcon = (fileName) => {
    if (!fileName || typeof fileName !== 'string') {
        return 'fas fa-file-alt'; 
    }
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
        case 'docx':
            return 'fas fa-file-word';
        case 'pdf':
            return 'fas fa-file-pdf';
        case 'jpg':
        case 'jpeg':
        case 'png':
            return 'fas fa-file-image';
        default:
            return 'fas fa-file-alt';
    }
};


const handleSaveFile = (file) => {
    const fileName = file.name || file.imageName; 
    const fileUrl = file.url || file.imageUrl; 
    const url = fileUrl ? fileUrl : URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click(); 
    document.body.removeChild(a); 
    if (!fileUrl) {
        URL.revokeObjectURL(url);
    }
};


const handlePreviewFile = (file) => {
    const fileUrl = file.url || file.imageUrl; 
    setPreviewFile(fileUrl ? fileUrl : URL.createObjectURL(file)); 
    setIsModalOpen(true); 
};

const closePreview = () => {
    setPreviewFile(null); 
    setIsModalOpen(false); 
};
let newFiles;
const handleDeleteFile = (index) =>{
newFiles = uploadedFiles.filter((item,ind) => ind !== index)
setUploadedFiles(newFiles)
}
    console.log("project details in customer",projectDetails)

    const fieldValidations = () => {
        let isValid = true
        
        if (!projectDetails?.projectName) {
            setErrorMsg((prev) => ({ ...prev, projectName: "Project name is required" }))
            isValid = false
        }
        if (!projectDetails?.contractType) {
            setErrorMsg((prev) => ({ ...prev, contractType: "Contract type is required" }))

            isValid = false
        }
        if (!projectDetails?.projectLocation) {
            setErrorMsg((prev) => ({ ...prev, projectLocation: "Project Location is required" }))
            isValid = false
        }
        if (!projectDetails?.projectLatitude) {
            setErrorMsg((prev) => ({ ...prev, projectLatitude: "Project Latitude is required" }))
            isValid = false
        }
        if (!projectDetails?.projectLongitude) {
            setErrorMsg((prev) => ({ ...prev, projectLongitude: "Project Longitude is required" }))
            isValid = false
        }
        if (!projectDetails?.pinCode || !projectDetails?.pinCode.length === 6) {
            setErrorMsg((prev) => ({ ...prev, pinCode: "Pin code  is required" }))
            isValid = false
        }
        // if (!projectDetails?.city) {
        //     setErrorMsg((prev) => ({ ...prev, city: "City is required" }))
        //     isValid = false
        // }
        if (!projectDetails?.projectType) {
            setErrorMsg((prev) => ({ ...prev, projectType: "Contract type is required" }))
            isValid = false
        }
        if (!projectDetails?.projectType) {
            setErrorMsg((prev) => ({ ...prev, projectType: "Project type is required" }))
            isValid = false
        } else if (projectDetails?.projectType === "Others") {
            if (!projectDetails?.noOfFloors) {
                setErrorMsg((prev) => ({ ...prev, noOfFloors: "No of floors is required" }))
                isValid = false
            } else if (!(projectDetails.noOfFloors > 0 && projectDetails.noOfFloors < 5)) {
            }
        } else {
            setErrorMsg((prev) => ({ ...prev, noOfFloors: "" }))
        }
        if (!projectDetails?.plotArea) {
            setErrorMsg((prev) => ({ ...prev, plotArea: "Plot Area is required" }))
            isValid = false
        }
        if (!projectDetails?.slabArea) {
            setErrorMsg((prev) => ({ ...prev, slabArea: "Slab Area is required" }))
            isValid = false
        }
        if (projectDetails?.penthouse === "yes") {
            if (!projectDetails?.penthouseSlabArea) {
                setErrorMsg((prev) => ({ ...prev, penthouseSlabArea: "Pent house is required" }))
                isValid = false
            }else{
                 setErrorMsg((prev) => ({ ...prev, penthouseSlabArea: "" }))
                 isValid = true
            }
        }else{
              setErrorMsg((prev) => ({ ...prev, penthouseSlabArea: "" }))
             
        }
        if (!projectDetails?.buildArea) {
            setErrorMsg((prev) => ({ ...prev, buildArea: "Build Area is required" }))
            isValid = false
        }
        if (!projectDetails?.totalCost) {
            setErrorMsg((prev) => ({ ...prev, totalCost: "TotalCost is required" }))
            isValid = false
        }
        if (!projectDetails?.status) {
            setErrorMsg((prev) => ({ ...prev, status: "status is required" }))
            isValid = false
        }
        if (projectDetails.permissions[0] === "yes") {
            if (!uploadedFiles || uploadedFiles.length === 0) {
                toast.error("At least one file must be uploaded");
                isValid = false;
            }
            
        } else if (projectDetails.permissions[0] === "No") {
            
        }
    
        return isValid
    }

    const updateOrAddApi = async (typeofApi) => {
        console.log("update or add api ", typeofApi)
        try {
            if (typeofApi === "add") {
                const filesData = new FormData();
        
        if (uploadedFiles && uploadedFiles.length > 0) {
            console.log(uploadedFiles,"uploadedFiles")
            for (let i = 0; i < uploadedFiles.length; i++) {

                filesData.append("Files", uploadedFiles[i])
            }
        } else {
            filesData.append("Files", null); 
        }
        filesData.append("projectId", projectDetails?.projectId)
        filesData.append("projectName", projectDetails.projectName);
        filesData.append("contractType", projectDetails.contractType);
        filesData.append("projectLocation", projectDetails.projectLocation);
        filesData.append("pinCode", projectDetails.pinCode);
        filesData.append("city",seCity);
        filesData.append("projectType", projectDetails.projectType);
        filesData.append("noOfFloors", projectDetails?.noOfFloors === "" ? "1" : projectDetails?.noOfFloors);

        filesData.append("plotArea", Number(projectDetails.plotArea));
        filesData.append("slabArea", Number(projectDetails.slabArea));
        filesData.append("penthouse", projectDetails.penthouse);
        filesData.append("penthouseSlabArea", projectDetails.penthouseSlabArea || ""); 
        filesData.append("buildArea", Number(projectDetails.buildArea));
        filesData.append("totalCost", Number(projectDetails.totalCost));
        filesData.append("permissions", `{${projectDetails.permissions.join(',')}}`);

        filesData.append("status", projectDetails.status);
        
        const latitude = projectDetails.projectLatitude;
        const longitude = projectDetails.projectLongitude ;
        console.log(latitude,longitude,"latlong values")
        filesData.append("projectCoordinates", latitude && longitude ? `${latitude},${longitude}` : null);
        
        filesData.append("customerId", customerIdFromApi);
        filesData.append("salesExecutiveId", Number(window.localStorage.getItem("user_Id")));
        filesData.append("creationDate", date);
        
        console.log("Creation Date of the project: " + date);
        
                const response = await addProjectService(filesData);

                if (response.status === "success") {
                    setProjectDetails((values) => ({
                        ...values,
                        projectId: response?.projectId,
                        customerId: response?.customerId
                    }))
                    projectDetailsForPayments({
                        projectId: response?.projectId,
                        projectType: response?.projectType,
                        type: "add"
                    })
                    toast.success("successfully added the project")
                    handleTabs("Payments")
                } else {
                    toast.error("Failed to add the project")
                }
            } else if (typeofApi === "update") {
                const filesData = new FormData();
                if (uploadedFiles && uploadedFiles.length > 0) {
                    const metaDataObjects = [];
                    
                    uploadedFiles.forEach((file, index) => {
                        if (file instanceof File) {
                            filesData.append("Files", file); 
                        } else {
                            metaDataObjects.push(file); 
                        }
                    });
                
                    if (metaDataObjects.length > 0) {
                        filesData.append("MetaData", JSON.stringify(metaDataObjects));
                    }
                } else {
                    filesData.append("Files", null); 
                }
            
                
                filesData.append("projectId", projectDetails?.projectId)
                filesData.append("projectName", projectDetails.projectName);
                filesData.append("contractType", projectDetails.contractType);
                filesData.append("projectLocation", projectDetails.projectLocation);
                filesData.append("pinCode", projectDetails.pinCode);
                filesData.append("city", seCity);
                filesData.append("projectType", projectDetails.projectType);
                filesData.append("noOfFloors", projectDetails?.noOfFloors === "" ? 1 : projectDetails?.noOfFloors);
        
                filesData.append("plotArea", Number(projectDetails.plotArea));
                filesData.append("slabArea", Number(projectDetails.slabArea));
                filesData.append("penthouse", projectDetails.penthouse);
                filesData.append("penthouseSlabArea", projectDetails.penthouseSlabArea || ""); 
                filesData.append("buildArea", Number(projectDetails.buildArea));
                filesData.append("totalCost", Number(projectDetails.totalCost));
                filesData.append("permissions", `{${projectDetails.permissions.join(',')}}`);
        
                filesData.append("status", projectDetails.status);
                
                const latitude = projectDetails.projectLatitude || "";
                const longitude = projectDetails.projectLongitude || "";
                filesData.append("projectCoordinates", latitude && longitude ? `${latitude},${longitude}` : null);
                
                filesData.append("customerId", customerIdFromApi);
                filesData.append("salesExecutiveId", Number(window.localStorage.getItem("user_Id")));
                filesData.append("creationDate", date);
             
                const response = await updateProjectService(filesData)
                if (response.status === "success") {
                    toast.success("Updated Project successfully")
                    projectDetailsForPayments({
                        projectId: response.projectId,
                        projectType: response.projectType,
                        projectAmount: response.projectAmount,
                        type: "edit"
                        
                    })
                    handleTabs("Payments")
                } else {
                    toast.error("Failed to Update Project")
                }
            }
        } catch (error) {
            console.log("error", error)
        }
    }

 
    const submitHandler = async () => {
        console.log("inside the submit handleer")
        if (isLoading) return; 

        if (fieldValidations()) {
            setIsLoading(true);
            try{
            if (type === "add" || type === "newlead") {
                if (projectDetails?.projectId) {
                    await updateOrAddApi("update")
                } else {
                   await updateOrAddApi("add")
                }
            } else if (type === "edit" || type === "updateproject") {
                if (projectDetails?.projectId) {
                  await  updateOrAddApi("update")
                } else {
                   await updateOrAddApi("add")
                }
                
            }
        }catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred. Please try again."); 
          } finally {
            setIsLoading(false);
          }
        }
    }
    const getProjectById = async () => {
        try {
            const response = await getProjectByCustomerId({ customerId: customerIdFromApi })
            if (response.status === "success") {
                console.log("customer response", response)
                setProjectDetails({ ...response.data })
                console.log(projectDetails,"project Details")
                const projectFiles = response?.data?.projectFiles || [];
                const formattedFiles = projectFiles.filter((file)=> file.imageUrl !== null).map((file) => ({
                    name: file.imageName,
                    url: file.imageUrl,
                    type: file.objectType, 
                    objectUuid:file.objectUuid
                }));
    
              
                setUploadedFiles(formattedFiles);
            } else {
                console.log("no data found in project")
            }
        } catch (error) {
            console.log("error", error)
            toast.error("failed to fetch the project data")
        }
    }


    const getUserCity = async () =>{
    
        const body = {
            userId : Number(window.localStorage.getItem("user_Id"))
        }
        const response = await getUserCities(body);
         setSeCity(response.city);
    
    }


    useEffect(() => {
        getUserCity();

        if (type === "edit" || type === "updateproject") {
            console.log(projectDetails)
                getProjectById()

        } else if (type === "newlead") {
            setProjectDetails((values) => ({ ...values,seCity: newLead?.area }))
        }
    }, [])

    useEffect(() => {
        dispatch(getStatusListType("Project"))
        dispatch(GETPROJECTTYPELIST())
    }, [])

    useEffect(() => {
        if (projectDetails?.penthouse === "No") {
            setProjectDetails((prevState) => ({ ...prevState, penthouseSlabArea: "" }))
        }
    }, [projectDetails?.penthouse])
  
    useEffect(() => {
        if (projectDetails?.projectType !== "Others") {
            setProjectDetails((prevState) => ({ ...prevState, noOfFloors: "" }))
        } 
    }, [projectDetails?.projectType])

    console.log("project details-------", projectDetails, date)
   
  
    useEffect(() => {
        const isFormValid = () => {
          const { 
            projectName, contractType, projectLocation, pinCode, projectType, 
            plotArea, slabArea, buildArea, totalCost, permissions 
          } = projectDetails;
      
          return projectName && contractType && projectLocation && pinCode  && projectType && 
                 plotArea && slabArea && buildArea && totalCost && permissions.length > 0;
        };
      
        setIsSaveDisabled(!isFormValid());
      }, [projectDetails]);  
    
    return (
        <div className="contentBody detail">
            <div className="row">
                <div className="col-md-8" style={{ margin: "auto" }}>
                    <div className="whCard">
                        <div className="cardHeader">
                            <span>Projects Details</span>
                            <div className="d-flex customer-details-btn">
                                <select
                                    value={projectDetails.status}
                                    name="status"
                                    className={`selectStatus ${
                                        (projectDetails.status === "Active" && "Active") ||
                                        (projectDetails.status === "Inactive" && "Inactive") ||
                                        (projectDetails.status === "Deleted" && "Deleted")
                                    }`}
                                    onChange={ProjectHandlerChange}
                                >
                                    <option value={"Draft"}>Draft</option>
                                </select>
                                <Tooltip title="Customer Details">
                                    <button className="btn whBtnPrimary" onClick={() => handleTabs("Customer")}>
                                        <BiLeftArrowAlt size="25px" className="" />
                                        Back
                                    </button>
                                </Tooltip>
                                <button onClick={() => submitHandler()} className="btn saveBtnclose" 
                                disabled={isSaveDisabled || isLoading}
                                >
{isLoading ? (
        <>
          <div className="spinner" style={{ display: 'inline-block', marginRight: '8px' }} />
          <span>Saving...</span>
        </>
      ) : (
        'Save & Next'
      )}
                                </button>
                            </div>
                        </div>
                        <div className="cardBody">
                            <div className="row">
                                <div className="row justify-content-end">
                                <div className='col justify-content-end' style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                                    <input
                                        type="checkbox"
                                        checked={dateEnabled}
                                        onChange={(e) => {setDateEnabled(e.target.checked);
                                            console.log("Checked date for project creation: ",e.target.checked);
                                            
                                        }}
                                    />
                                    
                                <label className="projectStartLabel">Set Project Start Date</label>
                                    <DatePicker
                                        onChange={(item) => setDate(item.toISOString().split("T")[0])}
                                        format={"DD-MM-YYYY"}
                                        size="small"
                                        disabled= {!dateEnabled}
                                        style={{width:"150px", justifyContent:"flex-end"}}
                                        disabledDate={disabledDate}
                                    /> 
                                </div>
                                </div>
                                <div className="col">
                                    <label>Project Name *</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={projectDetails.projectName}
                                            name="projectName"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectName && "errorInput"}`}
                                            placeholder="Enter Project Name"
                                        />
                                    </div>
                                    {errorMsg?.projectName ? (
                                        <small className="emailExist">{errorMsg?.projectName}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <label>Contract Type *</label>
                                    <div>
                                        <select
                                            value={projectDetails.contractType}
                                            onChange={ProjectHandlerChange}
                                            className={`form-select ${errorMsg?.contractType && "errorInput"}`}
                                            name="contractType"
                                        >
                                            <option hidden>select contract type</option>
                                            <option value="Caution Deposit">Caution Deposit</option>
                                            <option value="Signed">Signed</option>
                                        </select>
                                    </div>
                                    {errorMsg?.contractType ? (
                                        <small className="emailExist">{errorMsg?.contractType}</small>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div>
                                        <label>Project Location *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            value={projectDetails.projectLocation}
                                            name="projectLocation"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectLocation && "errorInput"}`}
                                            placeholder="Enter Project Location"
                                        />
                                        {/* <GooglePlacesAutocomplete/> */}
                                    </div>
                                    {errorMsg?.projectLocation ? (
                                        <small className="emailExist">{errorMsg?.projectLocation}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Latitude *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.projectLatitude}
                                            name="projectLatitude"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectLatitude && "errorInput"}`}
                                            placeholder="Enter Project Coordinates"
                                        />
                                    </div>
                                    {errorMsg?.projectLatitude ? (
                                        <small className="emailExist">{errorMsg?.projectLatitude}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Longitude *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.projectLongitude}
                                            name="projectLongitude"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectLongitude && "errorInput"}`}
                                            placeholder="Enter Project Longtitude"
                                        />
                                    </div>
                                    {errorMsg?.projectLongitude ? (
                                        <small className="emailExist">{errorMsg?.projectLongitude}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>PinCode *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            value={projectDetails.pinCode}
                                            name="pinCode"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.pinCode && "errorInput"}`}
                                            placeholder="Enter PinCode"
                                        />
                                    </div>
                                    {errorMsg?.pinCode ? (
                                        <small className="emailExist">{errorMsg?.pinCode}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                            <label>City *</label>
                                            {/* <CustomSelect
                                             value={details?.city}
                                             mode="-"
                                             className={`form-select ${errorMsg?.city && "errorInput"}`}
                                             placeholder={"Select Your City"}
                                             options={Options.cityOptions}
                                             onChange={handleFields("city")}
                                            /> */}
                                              <input
                                                type="text"
                                                value={seCity}
                                                className={`form-control ${errorMsg?.city && "errorInput"}`}
                                                name='city'
                                                readOnly
                                            />
                                              {errorMsg?.city ? (
            <small className="emailExist">{errorMsg?.city}</small>
        ) : null}
                                        </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Type Of Project *</label>
                                    </div>
                                    <div>
                                        <select
                                            value={projectDetails.projectType}
                                            onChange={ProjectHandlerChange}
                                            className={`form-select ${errorMsg?.projectType && "errorInput"}`}
                                            name="projectType"
                                        >
                                            <option hidden>select Project</option>
                                            {projectTypeList?.map((project) => {
                                                return <option value={project?.value}>{project?.label}</option>
                                            })}
                                        </select>
                                    </div>
                                    {errorMsg?.projectType ? (
                                        <small className="emailExist">{errorMsg?.projectType}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Number Of Floors *</label>
                                    </div>
                                    <div>
                                        {
                                            <input
                                                type="number"
                                                value={projectDetails?.noOfFloors}
                                                name="noOfFloors"
                                                onChange={ProjectHandlerChange}
                                                className={`form-control ${errorMsg?.noOfFloors && "errorInput"}`}
                                                placeholder="No of floors between 0 and 5"
                                                onWheel={(e) => e.currentTarget.blur()}
                                                disabled={projectDetails?.projectType !== "Others" ? true : false}
                                            />
                                      
                                        }
                                    </div>
                                    {errorMsg?.noOfFloors ? (
                                        <small className="emailExist">{errorMsg?.noOfFloors}</small>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Plot Area(Sq yd)*</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.plotArea}
                                            name="plotArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.plotArea && "errorInput"}`}
                                            placeholder="Enter Plot Dimensions"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.plotArea ? (
                                        <small className="emailExist">{errorMsg?.plotArea}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Slab Area (sq.ft) *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.slabArea}
                                            name="slabArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.slabArea && "errorInput"}`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Slab Area"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.slabArea ? (
                                        <small className="emailExist">{errorMsg?.slabArea}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Penthouse</label>
                                    </div>
                                    <div>
                                        <select
                                            value={projectDetails.penthouse}
                                            onChange={ProjectHandlerChange}
                                            className={`form-select`}
                                            name="penthouse"
                                        >
                                            <option hidden>Need Penthouse ?</option>
                                            <option value="yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Penthouse Area (sq.ft) </label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.penthouseSlabArea}
                                            name="penthouseSlabArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Slab Area"
                                            onWheel={(e) => e.currentTarget.blur()}
                                            disabled={projectDetails?.penthouse === "No" ? true : false}
                                        />
                                    </div>
                                    {errorMsg?.penthouseSlabArea ? (
                                        <small className="emailExist">{errorMsg?.penthouseSlabArea}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Build Up Area (sq.ft)*</label>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails?.buildArea}
                                            name="buildArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.buildArea && "errorInput"}`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Build Area"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.buildArea ? (
                                        <small className="emailExist">{errorMsg?.buildArea}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <label>Total Cost *</label>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.totalCost}
                                            name="totalCost"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.totalCost && "errorInput"}`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Total cost"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.totalCost ? (
                                        <small className="emailExist">{errorMsg?.totalCost}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Permissions</label>
                                    <div className="row">
                                        <div className="col-3"> Type</div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 text-center"> Yes</div>
                                                <div className="col-6 text-center">No</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-3"> Government</div>
                                        <div className="col-6">
                                            <Radio.Group
                                                name="government"
                                                onChange={ProjectHandlerChange}
                                                className="row"
                                                value={projectDetails?.permissions[0]}
                                                style={{ display: "flex" }}
                                            >
                                                <div className="col-6 text-center">
                                                    <Radio value={"yes"} />
                                                </div>
                                                <div className="col-6 text-center">
                                                    <Radio value={"No"} />
                                                </div>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {showUpload && (
                                        <>
                    <div className="upload-container">
                        <input 
                            type="file" 
                            multiple 
                            accept=".jpg,.jpeg,.png,.pdf,.docx"
                            onChange={handleFileChange} 
                            style={{ display: 'none' }} 
                            id="file-upload"
                        />
                        <label htmlFor="file-upload" style={{ width: '100%', height: '100%' }}>
                            <span className="upload-text">Upload Documents</span>
                            <div className="upload-area">
                                <div className="upload-instruction">
                                    <i className="fas fa-images upload-doc-icon"></i>
                                    <p>
                                        <span className="upload-link">Click to upload</span> or drag and drop
                                    </p>
                                    <p className="file-types">JPG, JPEG, PNG, PDF, DOCX</p>
                                </div>
                            </div>
                        </label>
                    </div>
                    {uploadedFiles && uploadedFiles.length > 0 && (
<div className="uploaded-files-list">
<ul className="file-list">
{uploadedFiles.map((file, index) => (
    (file.url || file.name) &&
    <li key={index} className="file-item">
        <div className="file-details">
        <i className={`file-icon ${getFileIcon(file.name || file.imageName)}`}></i>          
        <span className="file-name">{file.name || file.imageName}</span>
        </div>
        <div className="file-actions">
            <button className="action-btn" onClick={() => handleSaveFile(file)}>
                <HiOutlineSaveAs className="save-icon" />
            </button>
            <button className="action-btn" onClick={() => handlePreviewFile(file)}>
                <MdPreview className="preview-icon" />
            </button>
            <button className="action-btn">
                <RiDeleteBin6Line className="delete-icon" onClick={()=>handleDeleteFile(index)}/>
            </button>
        </div>
    </li>
))}

</ul>
</div>
)}
                    </>
                    
                )}

{isModalOpen && (
    <div className="modal-overlay">
        <div className="modal-container">
            <iframe
                src={previewFile}

                title="File Preview"
            />
            <button className="close-btn" onClick={closePreview}>&times;</button>
        </div>
    </div>
)}



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails

{
    /* <option hidden>select Project</option>
                                                <option>Apartment</option>
                                                <option>Commerical</option>
                                                <option>Duplex Villa</option>
                                                <option>Residential</option>
                                                <option>Triplex</option>
                                                <option>Villa</option> */
}
{
    /* <div className='row'>
                                        <div className="col-3"> Electricity</div>
                                        <div className='col-6'>
                                            <Radio.Group name="electricity" onChange={ProjectHandlerChange} className='row' value={projectDetails?.permissions[1]} style={{ display: "flex" }}>
                                                <div className='col-6 text-center'><Radio value={"yes"} /></div>
                                                <div className='col-6 text-center'><Radio value={"No"} /></div>
                                            </Radio.Group>
                                        </div>
                                        <div className='col-3 text-center'>
                                            <Upload disabled={projectDetails?.permissions[1]}>
                                                <BsUpload />
                                            </Upload>
                                        </div>
                                        <div className='upload documents'></div>
                                    </div> */
}

{
    /* <option value="G">G</option>
                                            <option value="G + 1">G + 1</option>
                                            <option value="G + 2">G + 2</option>
                                            <option value="G + 3">G + 3</option>
                                            <option value="G + 4">G + 4</option>
                                            <option value="G + 5">G + 5</option> */
}
{
    /* {statusList && statusList.map((item, index) => (
                                        <option key={index} value={item.status}>{item.status}</option>
                                    ))} */
}
