import PMOServices from "../../Service/PMOServices";

const fetchNotifications = async (setNotifications) => {
  try {
    const payload = {
      userId: Number(window.localStorage.getItem("user_Id")),
    };
    const response = await PMOServices.getNotificationService(payload);
    console.log(response.data, "notificationResponse");
const filteredNotifications =  response?.data?.filter(
  (notification) => !notification.content.includes('\"')
);



    const mappedNotifications = filteredNotifications
      ?.map((notification) => {
        console.log(notification, "notification data");

        let mappedNotification = null;
        const content = notification.notificationsData;
        let title = `Project ${content.project_name}`;
        let description = "";

        if (notification.entityTypeId === 2) { // Project Notifications
          const allocatedTo = `${content.pmo_name} ${content.pm_name} ${content.pc_name} and ${content.customer_name}`;
          const budget = content.budget;
          const timeline = `${content.start_date} - ${content.end_date}`;

          description = `Allocated to ${allocatedTo}`;
          mappedNotification = {
            title,
            description,
            budget: `Budget: ${budget}`,
            timeline: `Timeline: ${timeline}`,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: content.isRead,
          };

          console.log(mappedNotification, "mappedNotification");
        } else if (notification.entityTypeId === 3) { // Subtask Notifications
          switch (content._task_status_name) {
            case "In-progress Requested":
              description = `SubTask ${content._task_type_name} of milestone ${content._milestone_name} is in In-progress.`;
              break;
            case "In Progress":
              description = `In-progress of SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is Approved by ${content._actor_name}`;
              break;
            case "In Progress - Approved by PM":
              description = `In-progress Request of SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is Approved by ${content.pm_name}`;
              break;
            case "Completed Requested":
              description = `${content._task_status_name} by ${content.pc_name} for SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name}`;
              break;
            case "Completed":
              description = `SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is ${content._task_status_name}`;
              break;
            case "Completed - Approved by PM":
              description = `Completed Request of SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is Approved by ${content.pm_name}`;
              break;
            default:
              description = "Unknown task status";
          }

          mappedNotification = {
            title,
            description,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: content.isRead,
          };
        }

        return mappedNotification;
      })
      .filter((notification) => notification !== null)
      .reduce((acc, current) => {
        if (!acc.some((notif) => notif.notificationId === current.notificationId)) {
          acc.push(current);
        }
        return acc;
      }, []);

    setNotifications(mappedNotifications);
  } catch (err) {
    console.error("Error fetching notifications:", err);
  }
};

export default fetchNotifications;
