import { gql } from '@apollo/client';


export const GET_ROOM_DETAILS_BY_ID=gql`
query GetRoomDetails($roomId: String!) {
    getRoomDetails(room_id: $roomId) {
      created_by_user_id
      id
      type
      associatedUsers {
        user_details {
          user_name
          user_id
        }
        room_role
      }
      name
    }
  }
`