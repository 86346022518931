import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(
  () => ({
    overlay: {
        position: "fixed" as "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1300,
        
      },
      popup: {
        background: "white",
        padding: "20px",
        borderRadius: "8px",
        width: "400px",
        maxHeight: "80%",
        overflowY: "auto" as "auto",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        zIndex: 1400,
      },
      field: {
        marginBottom: "20px",
      },
      input: {
        width: "100%",
        height:"40px",
    
        
      },
      userList: {
        display: "flex",
        flexDirection: "column" as "column",
      },
      userItem: {
        marginBottom: "10px",
      },
      actions: {
        display: "flex",
        justifyContent: "flex-end",
      },
      submitButton: {
        backgroundColor: "#4CAF50",
        color: "white",
        border: "none",
        padding: "5px 15px",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize:"13px"
      },
      cancelButton: {
        backgroundColor: "#f44336",
        color: "white",
        border: "none",
        padding: "10px 20px",
        borderRadius: "4px",
        cursor: "pointer",
      },
      CloseButtonContainer:{
        display:"flex",
        justifyContent:"space-between",
        marginBottom:'20px'
      },
      closeButton:{
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        top: "20px",
        right: "16px",
        // backgroundColor:"#f7f7f7",
        background:"url(https://pms.voltussprint.com/dist/icons/close.png) center center / 18px no-repeat;",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        cursor:"pointer"
      },
      groupName:{
        // paddingLeft: "15px",
        paddingBottom: "5px",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        lineHeight: "18.29px",
        color: "#010929",
        maxWidth: "190px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },    
}));