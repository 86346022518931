import { makeStyles } from 'tss-react/mui';

export const useStyles=makeStyles()(()=>({
    listItemMainContainer:{
        // width:'330px',
        marginTop:'5px',
        display:'flex',
        justifyContent:'start',
        alignItems:'center',
        cursor:"pointer",
        padding:"0 5px",
        width:'375px',
        
        '&:hover':{
            backgroundColor:'#F4F5F9',
            borderRadius:'10px'
            
        }
    },
 
    roomItemImage:{
        height:"40px",
        width:'40px',
        borderRadius:'20px'
    },
    RoomItemName:{
        paddingLeft:'15px',
        paddingBottom:'5px',
        fontSize:"14px",
        fontFamily:'Montserrat',
        fontWeight:'500',
        lineHeight:'18.29px',
        color:'#010929',
        maxWidth:'190px',
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap'
    },
    lastMessage:{
        paddingLeft:'15px',
        fontSize:'13px',
        fontFamily:'Montserrat',
        fontWeight:'300',
        color:'#667085',
        lineHeight:'15.29px',
        overflow:'hidden',
        textOverflow:'ellipsis',
        maxWidth:'160px',
        whiteSpace:'nowrap'
    },
    roomItemMessageContaine:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-around',
        
    },
    lastMessageUnreadTime:{
        color:'#2688de'
    },
    groupNameIcon: {
        width: '38px',               
        height: '38px',              
        borderRadius: '50%',         
        display: 'flex',             
        alignItems: 'center',        
        justifyContent: 'center',    
        fontSize: '14px',            
        fontWeight: '600',          
        color: '#dfe6dc',               
        position: 'relative',        
        backgroundColor: '#FF5733',  
      },
      

      statusIndicator: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        border: '2px solid white',  // White border for a clean look
        backgroundColor: '#44D62C', // Green for online (change dynamically)
      },
    
      offline: {
        backgroundColor: '#D3D3D3', // Grey for offline
      },
    lastMessageTimeStamp: {
        paddingBottom: '0px',
        marginRight: '15px',
        fontFamily: 'Montserrat',
        marginLeft: 'auto', // Ensures alignment to the end
        fontSize: '12px',
        color: '#AAAAAA',
        height: '60px',
        display: 'flex',
        flexDirection: 'column', // Stacks items vertically
        justifyContent: 'space-around', // Creates space between timestamp and count
        alignItems: 'flex-end', // Aligns to the right
        whiteSpace: 'nowrap',
      },
      
      roomMessageCount: {
        textAlign: 'center',
        backgroundColor: '#52a8f2',
        height: '23px',
        width: '23px',
        fontSize: '11px',
        fontWeight: '500',
        borderRadius: '11.5px', // Proper circle alignment
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5px', // Adds spacing from the timestamp
      },
    
}))