import { gql } from '@apollo/client';

export const GET_ALL_USERS= gql`
    query MyQuery {
    getAllUsers(search_text: "") {
      user_name
      email_id
      user_id
    }
  }
`;
