

import { gql } from '@apollo/client';

export const GET_ROOM_MESSAGES = gql`
              query getAllMessagesByRoom($user_id:ID!,$room_id:ID!) {
                getAllMessagesByRoom(user_id:$user_id,room_id: $room_id) {
                  Messages {
                    message_id
                    payload
                    attachments
                    message_type
                    sender_id
                    created_date
                    message_type
                    current_users_count
                    read_by_user_ids
                    sender_details {
                      user_name
                      user_id
                      email_id
                    }
                  }
                }
              }
`;


