import { makeStyles } from 'tss-react/mui';
import chatBackground from '../../../assets/ChatBG.png';

export const useChatStyles = makeStyles<{
  isExpandChat: boolean;
  isRoomDescriptionClicked: boolean;
}>()(
  (
    _,
    {
      isExpandChat
      // isRoomDescriptionClicked
    }
  ) => ({

    
    chatContainer: {
      
      display: 'flex',
      flexDirection: 'column',
      // height: isExpandChat ? 'calc(95vh)' : 'calc(100vh)',:

      height:'100vh',
      fontFamily: 'Montserrat',
      top: '0px',
      width: '100%', // isExpandChat && isRoomDescriptionClicked ?'70%':
      // padding: '10px',
      marginTop: '0',
      marginLeft:'auto',
      alignSelf: 'flex-start',
      justifyContent: 'space-between',
      backgroundImage: `url(${chatBackground})`

      // border:'solid 1px red'
    },



    // chatContainer: {
    //   position: 'absolute', // Make it independent of surrounding elements
    //   right: '0', // Aligns it to the right
    //   top: '0', // Ensures it starts from the top
    //   width: '30%', // Adjust width as needed
    // height: isExpandChat ? 'calc(95vh)' : 'calc(100vh)',
    
    //   backgroundImage: `url(${chatBackground})`,
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'space-between',
    //alignSelf:'flex-start ,
    //   fontFamily: 'Montserrat'
    // },

    chatProfileIcon: {
      width: '40px',
      height: '40px',
      top: '4px',
      left: '48px',
      gap: '0px',
      borderradius: '76.88px 0px 0px 0px',
      opacity: '0px',
      marginRight: '10px'
    },
    dateSeparator: {
      textAlign: 'center',
      margin: '10px 0',
      color: '#888',
      fontSize: '14px'
    },
    dateStyle: {
      backgroundColor: '#fff',
      padding: '5px 10px',
      borderRadius: '5px',
      fontWeight: '400',
      fontSize: '11px',
      color: '#777',
      boxShadow: '0px 1px 3px 1px #0000001f'
    },
    chatUsernameHeader: {
      width: '100%',
      height: '50px',
      top: '-1px',
      gap: '0px',
      opacity: '0px',
      backgroundColor: '#ffffff',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '13px',
      font: 'Montserrat',
      color: '#181D27',
      fontWeight: '500',
      // padding:'5px',
      padding: '10px',
      lineheight: '28px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0.2px 2px'
    },

    chatUsername: {
      width: '314px',
      height: '50px',
      top: '-1px',
      gap: '0px',
      opacity: '0px',
      // backgroundColor: '#ffffff',
      textAlign: 'left',
      display: 'flex',
      fontSize: '13px',
      font: 'Montserrat',
      color: '#181D27',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontWeight: '500',
      padding: '10px',
      lineheight: '28px'
    },
    chatFullScreen: {
      width: '30px',
      height: '30px',
      top: '9px',
      borderRadius: '30px',
      opacity: '0px',
      border: '1px solid #CBD5E1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      marginRight: '10px'
    },

    chatBackButton:{
      width: '30px',
      height: '30px',
      top: '9px',
      borderRadius: '30px',
      opacity: '0px',
      border: '1px solid #CBD5E1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      marginRight: '10px'


    },
    chatMessagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      // height: '80vh',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
      // backgroundColor: '#f9f9f9',
      // backgroundImage: `url(${chatBackground})`,
      // backgroundSize: 'cover', // Optional, adjusts the size of the background
      // backgroundRepeat: 'no-repeat', // Prevents tiling
      paddingLeft: isExpandChat ? '15%' : '0px',
      paddingRight: isExpandChat ? '15%' : '0px'
    },
    chatMessage: {
      margin: '10px 0',
      padding: '10px',
      borderRadius: '8px',
      // backgroundColor: '#f1f1f1',
      fontSize: '14px',
      lineHeight: '1.5',
      display: 'flex',
      alignItems: 'flex-start',
      gap: '8px'
    },
    leftAligned: {
      justifyContent: 'flex-start'
    },
    rightAligned: {
      justifyContent: 'flex-end'
    },
    messageCard: {
      minWidth: '70px',
      maxWidth: '85%',
      padding: '12px 12px 2px 12px',
      borderRadius: '0px 16px 16px 16px',
      backgroundColor: '#fff',
      boxShadow: '0px 2px 2px #00000033',
      wordWrap: 'break-word',
      gap: '4px'
    },
    activeUserMessageCard: {
      minWidth: '70px',
      maxWidth: '70%',
      borderRadius: '16px 0px 16px 16px',
      // backgroundColor: '#4882F4',
      backgroundColor:"#F8A504",
      wordWrap: 'break-word',
      padding: '12px 6px 2px 12px',
      gap: '4px',
      opacity: '0px',
      boxShadow: '0px 2px 2px #00000033',
      fontfamily: 'Montserrat',
      fontsize: '14px',
      fontweight: '500',
      letterspacing: '-0.007em',
      textalign: 'left',
      textunderlineposition: 'from-font',
      textdecorationskipink: 'none',
      color: '#FFFFFF!'
    },
    sender: {
      fontWeight: '400',
      color: '#1E293B',
      fontSize: '11px',
      marginBottom: '5px'
    },
    activesender: {
      marginBottom: '5px',
      fontSize: '12px',
      fontWeight: '300',
      color: '#FFFFFF'
    },
    messageText: {
      margin: '0px',
      fontSize: '14px',
      color: '#1E293B',
      fontWeight: '400'
    },
    activeMessageText: {
      margin: '0px',
      fontSize: '14px',
      fontWeight: '400',
      color: '#FFFFFF'
    },
    timestamp: {
      fontSize: '11px',
      color: '#475569',
      marginTop: '5px',
      textAlign: 'right',
      lineHeight: '16px',
      fontFamily: 'Montserrat'
    },
    activeMessageTimestamp: {
      fontSize: '11px',
      color: '#FFFFFF',
      display: 'block',
      maxWidth: '95%',
      textAlign: 'right',
      alignItems: 'center',
      marginBottom: '0px',
      fontFamily: 'Montserrat'
    },
    chatDoubleTick: {
      color: '#475569'
    },
    activeUserChatDoubleTick: {
      color: '#A5B4FC'
    },

    chatTimestampTicks: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '5px',
      margin: '0px'
    },
    chatInputContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      borderTop: '1px solid #ccc',
      backgroundColor: '#fff'
    },
    chatInput: {
      flex: 1,
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '14px'
    },
    sendButton: {
      marginLeft: '10px',
      padding: '10px 20px',
      border: 'none',
      backgroundColor: '#007bff',
      color: '#fff',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px'
    },
    messagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      // padding: 10px,
      overflowY: 'auto',
      // height: 100%;
      scrollBehavior: 'smooth'
    },
    typingStatus: {
      color: '#a7a8a7',
      margin: '0px',
      // maxWidth: "200px",
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block'
    },
    typingStatusContainer: {
      display: 'flex'
    },
    userTyping: {
      color: '#a7a8a7'
    },
    chatAttachmentImage:{
     display: 'flex',
      flexWrap: 'wrap',
       gap: '8px' 
    }
  })
);
