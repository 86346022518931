const servicesOptions = [
    { label: "Residential Construction", value: "Residential Construction" },
    { label: "Commercial Construction", value: "Commercial Construction" },
    { label: "Architecture Services", value: "Architecture Services" },
    { label: "Interior & smart Homes", value: "Interior & smart Homes" },
    // { label: "Project Management", value: "Project Management" }
]

const cityOptions = [
    { label: "Hyderabad", value: "Hyderabad" },
    { label: "Chennai", value: "Chennai" },
    {label:"Vijayawada",value:"Vijayawada"},
    {label:"Bangalore",value:"Bangalore"},
    {label:"Others",value:"Others"}
]
const projectTypes = [
    { label: "Caution Deposit", value: "Caution Deposit" },
    { label: "Signed", value: "Signed" }

]

const statusOptions = [
    {
        label: "All", value: 9

    },

    {
        label: "Raw", value: 1

    },
    {
        label: "Allocated L1", value: 10

    },
    {
        label: "Allocated L2", value: 2

    },
    {
        label: "Discarded/Deleted", value: 3

    },


]



const reportingManagers = [
    {
        label: "Partner Executive", value: "Partner Executive"
    },
    {
        label: "Admin", value: "Admin"
    },
    {
        label: "TKP", value: "TKP"
    },
    {
        label: "Customers", value: "Customers"
    },
    {
        label: "Architect Partner", value: "Architect Partner"
    },
    {
        label: "Interior Designer", value: "Interior Designer"
    },
    {
        label: "Skilled Worker", value: "Skilled Worker"
    },
    {
        label: "Project Management Officer(PMO)", value: "Project Management Officer"
    },
    {
        label: "Project Coordinator", value: "Project Coordinator"
    },
    {
        label: "Skilled Worker", value: "Skilled Worker"
    }
]

const monthsOptions = [
    {
        label: "1 Month", value: 1
    },
    {
        label: "2 Months", value: 2
    },
    {
        label: "3 Months", value: 3
    },
    {
        label: "4 Months", value: 4
    },
    {
        label: "5 Months", value: 5
    },
    {
        label: "6 Months", value: 6
    },
    {
        label: "7 Months", value: 7
    },
    {
        label: "8 Months", value: 8
    },
    {
        label: "9 Months", value: 9
    },
    {
        label: "10 Months", value: 10
    },
    {
        label: "11 Months", value: 11
    },
    {
        label: "12 Months", value: 12
    },
    {
        label: "13 Months", value: 13
    },
    {
        label: "14 Months", value: 14
    },
    {
        label: "15 Months", value: 15
    },
    {
        label: "16 Months", value: 16
    },
    {
        label: "17 Months", value: 17
    },
    {
        label: "18 Months", value: 18
    }
]
const contractTypeOptions = [
    {
        label: "Signed",
        value: "Signed"
    },
    {
        label: "Contract",
        value: "Contract"
    }
]

export default {
    servicesOptions,
    cityOptions,
    projectTypes,
    statusOptions,
    reportingManagers,
    contractTypeOptions,
     monthsOptions 


}