import React from "react";
import { useStyles } from "./ContactItem.style";

const letterColors: { [key: string]: string } = {
  A: "#ffddd6",
  B: "#d6f0ff",
  C: "#daf7d5",
  D: "#fff3cc",
  E: "#C70039",
  F: "#f4ccd7",
  G: "#ded1da",
  H: "#d2e9ff",
  I: "#e1e9ec",
  J: "#ffe8cc",
  K: "#ffd0e9",
  L: "#eff9fa",
  M: "#d5e8dd",
  N: "#f05bee",
  O: "#e8cccc",
  P: "#ffdacc",
  Q: "#daf6f5",
  R: "#ffe0da",
  S: "#d9f9f6",
  T: "#e5e1fc",
  U: "#e1def5",
  V: "#e3edde",
  W: "#e0ecd4",
  X: "#e9e2f8",
  Y: "#d8f0e3",
  Z: "#dae6f0",
};

const textColors: { [key: string]: string } = {
  A: "#FF5733",
  B: "#33B5FF",
  C: "#44D62C",
  D: "#FFC300",
  E: "#C70039",
  F: "#900C3F",
  G: "#581845",
  H: "#1E90FF",
  I: "#6892A0",
  J: "#FF8C00",
  K: "#FF1493",
  L: "#B0E0E6",
  M: "#2E8B57",
  N: "#f05bee",
  O: "#8B0000",
  P: "#FF4500",
  Q: "#48D1CC",
  R: "#FF6347",
  S: "#40E0D0",
  T: "#7B68EE",
  U: "#6A5ACD",
  V: "#74a358",
  W: "#65a12a",
  X: "#9370DB",
  Y: "#3CB371",
  Z: "#4682B4",
};

const ContactItem: React.FC<{ contact: any }> = ({ contact }) => {
  const { classes } = useStyles();

  // Extract initials from the name
  const contactName = contact.user_details?.user_name;
  const splitContactName = contactName.split(" ");
  let groupName;

  if (splitContactName.length === 1) {
    groupName = splitContactName[0][0]?.toUpperCase();
  } else if (splitContactName.length > 1) {
    const first = splitContactName[0][0]?.toUpperCase();
    const second = splitContactName[1][0]?.toUpperCase();
    groupName = `${first}${second}`;
  }

  // Set background and text colors based on initials
  const initialColor = letterColors[groupName[0]] || "#FF5733";
  const initialTextColor = textColors[groupName[0]] || "#FF5733";

  return (
    <li className={classes.listItemMainContainer}>

      <div
        className={classes.groupNameIcon}
        style={{ backgroundColor: initialColor }}
      >
        <span style={{ color: initialTextColor }}>{groupName}</span>
      </div>
      <div className={classes.contactDetailsContainer}>
        <p className={classes.contactName}>
          {contact.user_details?.user_name || "Unknown Contact"}
        </p>
      </div>
    </li>
  );
};

export default ContactItem;
