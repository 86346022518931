import { useMutation } from '@apollo/client';
import { UPDATE_MESSAGE_STATUS } from "../../queries/updateMessageStatus";

export const useUpdateMessageStatus = () => {

  const [updateStatus, { data, loading, error }] = useMutation(UPDATE_MESSAGE_STATUS);

  const updateMessageStatus = async (roomId: string, userId: string, messageId: string) => {
    try {
      const response = await updateStatus({
        variables: {
          room_id: roomId,
          user_id: userId,
          message_id: messageId
        }
      });
      return response.data.updateMessageStatus;
    } catch (err: any) {
        if (err.message?.includes('ALREADY_READ')) {
        return "ALREADY_READ"; 
        }else{
            console.log("error from updateMessageStatusHook ============>")
        }
    }
  };


  return {
    updateMessageStatus,
    data,
    loading,
    error
  };
};



