import { makeStyles } from 'tss-react/mui';


export const useStyles = makeStyles()(
    () => ({
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          addUserModal: {
            position: 'relative',
            backgroundColor: '#fff',
            zIndex: 1500,
            display: 'flex',
            flexDirection: 'column',
            width: '500px',
            maxWidth: '90%',
            height: '600px',
            maxHeight: '90vh',
            borderRadius: '20px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden'
          },
          header: {
            backgroundColor: '#89a4c7',
            color: '#fff',
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          },
          headerTitle: {
            fontSize: '20px',
            fontWeight: 500,
          },
          searchBar: {
            padding: '8px',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            borderBottom: '1px solid #e0e0e0',
          },
          searchInput: {
            flex: 1,
            position: 'relative',
            backgroundColor: '#f0f2f5',
            borderRadius: '24px',
            padding: '6px 16px',
            '& input': {
              width: '100%',
              padding: '4px 24px 4px 8px',
            },
          },
          searchDot: {
            position: 'absolute',
            right: '16px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#89a4c7',
          },
          contactsHeader: {
            padding: '16px',
            color: '#89a4c7',
            fontWeight: 500,
          },
          contactsList: {
            flex: 1,
            overflowY: 'auto',
          },
          contactItem: {
            display: 'flex',
            alignItems: 'center',
            padding: '12px 16px',
            gap: '12px',
            borderBottom: '1px solid #f0f2f5',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#f0f2f5',
            },
          },
          avatar: {
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            backgroundColor: '#f0f2f5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            color: '#666',
          },
          contactInfo: {
            flex: 1,
          },
          contactName: {
            fontWeight: 500,
            marginBottom: '4px',
          },
          contactPhone: {
            fontSize: '14px',
            color: '#667781',
          },
          contactStatus: {
            fontSize: '14px',
            color: '#667781',
          },
          backButton: {
            color: '#89a4c7',
          },
          selectedArea: {
            padding: '8px 16px',
            borderBottom: '1px solid #e0e0e0',
            minHeight: '48px',
            overflowY:"auto",
            maxHeight:"90px"
          },
          selectedChips: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          },
          chip: {
            backgroundColor: '#e9edef',
            borderRadius: '16px',
            '& .MuiChip-deleteIcon': {
              color: '#667781',
              '&:hover': {
                color: '#667781',
              },
            },
          },
          chipAvatar: {
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: '#89a4c7',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
          },
          checkbox: {
            color: '#89a4c7',
            '&.Mui-checked': {
              color: '#89a4c7',
            },
          },
          footer: {
            padding: '16px',
            backgroundColor: '#fff',
            borderTop: '1px solid #e0e0e0',
            display: 'flex',
            // alignItems: 'center',
            // alignItems:"flex-end",
            justifyContent: 'flex-end',
          },
          footerText: {
            color: '#667781',
            fontSize: '14px',
            margin: 0,
          },
          footerLink: {
            color: '#89a4c7',
            cursor: 'pointer',
          },
          submitButton: {
            backgroundColor: '#fff',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            },
            width: '40px',
            height: '40px',
            alignSelf:"flex-end",
            marginRight:"10px"
          },
          submitIcon: {
            transform: 'rotate(135deg)',
          },
}));


