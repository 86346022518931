import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  modalOverlay: {
    position: "fixed" as "fixed",
    inset: 0,
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  modalContent: {
    position: "relative" as "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
 
  },
  navigationButton: {
    position: "absolute" as "absolute",
    padding: "8px",
    color: "#ffffff",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      transform: "scale(1.1)",
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  prevButton: {
    left: "16px",
  },
  nextButton: {
    right: "16px",
  },
  closeButton: {
    position: "absolute" as "absolute",
    top: "16px",
    right: "16px",
    padding: "8px",
    color: "#ffffff",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    transition: "all 0.2s ease",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      transform: "scale(1.1)",
    },
  },
  previewImage: {
    position: 'relative',
    maxWidth: 'min(80vw, 100%)', // Uses the smaller of 80vw or 100% of container
    maxHeight: 'min(80vh, 100%)', // Uses the smaller of 80vh or 100% of container
    width: 'auto',
    height: 'auto',
    objectFit: 'contain' as 'contain',
    borderRadius: '4px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    margin: 'auto', // Ensures centering
  
  },
  imageCounter: {
    position: "absolute" as "absolute",
    bottom: "16px",
    left: "50%",
    transform: "translateX(-50%)",
    color: "#ffffff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "4px 12px",
    borderRadius: "16px",
    fontSize: "14px",
    fontFamily: "Montserrat",
  },
}));

export default useStyles;